/* import __COLOCATED_TEMPLATE__ from './discount-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { NumberField } from '@repo/design-system-kit';

// @ts-expect-error
import { multiplyAndRound } from 'qonto/utils/receivable-invoicing';

import styles from './discount-field.strict-module.css';

const PERCENTAGE_SYMBOL = '%';
const DISCOUNT_PERCENTAGE = 'percentage';
const DISCOUNT_ABSOLUTE = 'absolute';

interface ReceivableInvoicesFormDiscountFieldSignature {
  // The arguments accepted by the component
  Args: {
    isDisabled?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesFormDiscountFieldComponent extends Component<ReceivableInvoicesFormDiscountFieldSignature> {
  numberField = NumberField;
  styles = styles;

  @tracked selectedDiscountType = this.discount?.type ?? 'percentage';

  @tracked discountValue = this.item.discount
    ? this.item.discount.type === 'percentage'
      ? this.item.discount.value * 100
      : this.item.discount.value
    : null;

  get item() {
    // @ts-expect-error
    return this.args.item;
  }

  get discount() {
    // @ts-expect-error
    return this.args.item?.discount;
  }

  get discountOptionsArray() {
    return [
      { value: DISCOUNT_PERCENTAGE, label: PERCENTAGE_SYMBOL },
      // @ts-expect-error
      { value: DISCOUNT_ABSOLUTE, label: this.args.currency },
    ];
  }

  get discountOptions() {
    return this.discountOptionsArray.map(item => item.label);
  }

  get selectedDiscountOption() {
    if (this.selectedDiscountType === DISCOUNT_ABSOLUTE) {
      // @ts-expect-error
      return this.args.currency;
    }
    return PERCENTAGE_SYMBOL;
  }

  get errorMessage() {
    if (this.selectedDiscountType === DISCOUNT_ABSOLUTE) {
      // @ts-expect-error
      return this.args.amountErrorMessage;
    } else if (this.selectedDiscountType === DISCOUNT_PERCENTAGE) {
      // @ts-expect-error
      return this.args.percentageErrorMessage;
    }
  }

  get formatOptions() {
    return {
      minimumFractionDigits: this.selectedDiscountType === DISCOUNT_ABSOLUTE ? 2 : null,
      maximumFractionDigits: 2,
    };
  }

  @action
  // @ts-expect-error
  handleDiscountOptions(option) {
    if (this.item.errors?.has('discountAmount')) {
      this.item.errors.remove('discountAmount');
    }

    let optionType = this.discountOptionsArray.find(item => item.label === option);

    // @ts-expect-error
    this.selectedDiscountType = optionType.value ?? null;

    // the percentage will be sent in decimals, so 10% = 0.1
    let percentageValue = this.setPercentageValue(this.discountValue);

    if (this.discountValue) {
      this.setDiscountObj(percentageValue, String(this.discountValue));
    }
  }

  // @ts-expect-error
  @action updateDiscount(value) {
    // remove error for discount amount
    if (this.item.errors?.has('discountAmount')) {
      this.item.errors.remove('discountAmount');
    }

    if (!value) {
      this.item.discount = null;
      this.discountValue = null;
    } else {
      // negative number are not allowed
      let positiveValue = value < 0 ? Math.abs(value) : value;
      let newValue =
        // @ts-expect-error
        positiveValue <= this.args.priceLimit
          ? String(positiveValue)
          : // @ts-expect-error
            String(this.args.priceLimit);

      // the percentage will be sent in decimals, so 10% = 0.1
      let percentageValue = this.setPercentageValue(newValue);

      this.setDiscountObj(percentageValue, newValue);

      this.discountValue = newValue;
    }
  }

  // @ts-expect-error
  setPercentageValue(value) {
    if (value) {
      // the percentage will be sent in decimals, so 11.11% = 0.1111
      let preciseValue = Math.round(parseFloat(value) * 100) / 100;
      let rate = preciseValue / 100;
      let preciseRate = Math.round(rate * 10000) / 10000;

      let stringValue = String(String(preciseRate).replace(/[,.]/g, '.'));
      return stringValue;
    }
    return null;
  }

  // @ts-expect-error
  setDiscountObj(percentageValue, absoluteValue) {
    this.item.discount = {
      type: this.selectedDiscountType,
      value: this.selectedDiscountType === 'percentage' ? percentageValue : absoluteValue,
      amount:
        this.selectedDiscountType === 'absolute'
          ? absoluteValue
          : String(multiplyAndRound(percentageValue, this.item?.totalExcludingVat, 100)),
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::DiscountField': typeof ReceivableInvoicesFormDiscountFieldComponent;
  }
}
