export default {
  "wrapper": "cre",
  "card-header": "crB",
  "subtitle-caption": "crE",
  "estimated-earnings": "crd",
  "progress": "crI",
  "progress-bar": "crt",
  "progress-bar-text": "crM",
  "subtitle": "crP",
  "badge": "crl",
  "core-information": "crX",
  "secondary-information": "crC",
  "remuneration-table": "crk",
  "name": "crJ title-3",
  "separator": "crs",
  "remuneration-line": "crF",
  "german-disclaimer": "crg",
  "table": "crD",
  "table-right": "crV"
};
