export default {
  "container": "cvS",
  "form-container": "cvc",
  "content": "cvq",
  "fields": "cvZ",
  "input-settings": "cvR",
  "current-year": "cvQ",
  "next-invoice-number": "cve",
  "preview-container": "cvB",
  "actions": "cvE",
  "close-button": "cvd btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cvI",
  "preview-image": "cvt"
};
