export default {
  "row": "cba",
  "empty": "cbH",
  "active": "cbO",
  "animated": "cbm",
  "fadein-item": "cbp",
  "cell": "cbx body-2",
  "cell-content": "cbw",
  "cell-amount": "cbN",
  "cell-content-amount": "cbv body-1"
};
