export default {
  "wrapper": "cOP",
  "per-page-container": "cOl",
  "options": "cOX",
  "option": "cOC",
  "active": "cOk",
  "description": "cOJ",
  "description-per-page": "cOs",
  "summary": "cOF"
};
