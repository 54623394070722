export default {
  "header": "cKc",
  "header-type": "cKq",
  "close": "cKZ",
  "close-icon": "cKR",
  "header-infos": "cKQ",
  "title": "cKe body-1",
  "declined": "cKB",
  "canceled": "cKE",
  "approved": "cKd",
  "pending": "cKI",
  "date": "cKt body-2",
  "mileage-icon": "cKM",
  "distance-subtitle": "cKP body-2",
  "link-icon": "cKl",
  "container": "cKX",
  "picto": "cKC",
  "general": "cKk",
  "amount": "cKJ",
  "calculation-container": "cKs",
  "counterparty": "cKF body-2",
  "infobox": "cKg"
};
