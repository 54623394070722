export default {
  "header": "cAr",
  "header-type": "cAb",
  "close": "cAy",
  "close-icon": "cAU",
  "header-infos": "cAj",
  "title": "cAW body-1",
  "declined": "cAu",
  "canceled": "cYS",
  "approved": "cYc",
  "pending": "cYq",
  "date": "cYZ body-2",
  "mileage-icon": "cYR",
  "distance-subtitle": "cYQ body-2",
  "link-icon": "cYe",
  "container": "cYB",
  "picto": "cYE",
  "general": "cYd",
  "amount": "cYI",
  "calculation-container": "cYt",
  "counterparty": "cYM body-2",
  "infobox": "cYP"
};
