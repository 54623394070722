export default {
  "selector": "cxV",
  "pension-selector-content": "cxL",
  "withholding-tax-selector-content": "cxT",
  "withholding-tax-selector-content-row": "cxz",
  "power-select-field": "cxa",
  "rate-field": "cxH",
  "stamp-revenue-field": "cxO",
  "dropdown-option": "cxm",
  "dropdown-content": "cxp"
};
