export default {
  "wrapper": "cru",
  "request-header": "cbS",
  "request": "cbc",
  "scheduled": "cbq",
  "scheduled-label": "cbZ",
  "note": "cbR",
  "note-label": "cbQ",
  "note-label-danger": "cbe",
  "note-content": "cbB",
  "status": "cbE",
  "transfer-amount": "cbd"
};
