export default {
  "header-cell": "cYh",
  "empty": "cYf",
  "header-bulk-checkbox": "cYK",
  "header-type": "cYG",
  "header-requester": "cYr",
  "header-date": "cYb",
  "header-amount": "cYy",
  "header-approval": "cYU",
  "header-content": "cYj caption-bold",
  "active": "cYW",
  "header-receipt": "cYu",
  "row-compact": "chS",
  "row-sidebar": "chc",
  "hidden": "chq",
  "animated": "chZ",
  "fadein-item": "chR",
  "animated-cell": "chQ",
  "checkbox": "che"
};
