export default {
  "file-uploader": "cOZ",
  "file-uploader-content": "cOR",
  "file-uploader-dropzone": "cOQ",
  "fixedSize": "cOe",
  "two-column": "cOB",
  "file-uploader-label": "cOE body-1",
  "required-chip": "cOd",
  "animated-container": "cOI",
  "toggle-button": "cOt",
  "toggle-button-icon": "cOM"
};
