export default {
  "mileage-validation": "cjF",
  "actions": "cjg",
  "action-btn": "cjD",
  "approve-btn": "cjV",
  "request-mileage-validation": "cjL",
  "account-selector": "cjT",
  "has-error": "cjz",
  "funds-disclaimer": "cja",
  "account-options": "cjH",
  "account-balance": "cjO",
  "warnings": "cjm"
};
