export default {
  "container": "cxt",
  "depositAmount": "cxM",
  "flexRow": "cxP",
  "depositInput": "cxl",
  "dropdown": "cxX",
  "itemRow": "cxC",
  "line": "cxk",
  "estimates-container": "cxJ",
  "estimates": "cxs",
  "dt": "cxF",
  "dd": "cxg"
};
