export default {
  "numbering-container": "cxx",
  "automatic-number-label": "cxw",
  "icon-tooltip": "cxN",
  "fix-width": "cxv",
  "dates-container": "cxo",
  "with-performance-date": "cxn",
  "date-picker": "cxi",
  "label": "cxA",
  "header-text-field-container": "cxY",
  "mt-16": "cxh",
  "header-text-field": "cxf",
  "header-text-field-close-button": "cxK",
  "deposit-toggle-container": "cxG"
};
