export default {
  "attachment-viewer": "cyz",
  "hide-sidebar": "cya",
  "sidebar": "cyH",
  "header": "cyO",
  "header-top": "cym",
  "header-icon": "cyp",
  "header-amount": "cyx",
  "infobox": "cyw",
  "vat": "cyN",
  "validation": "cyv"
};
