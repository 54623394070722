export default {
  "content": "crL",
  "balance": "crT",
  "duration": "crz",
  "green-text": "cra",
  "balance-subtitle": "crH",
  "balance-duration": "crO",
  "footer": "crm",
  "progress": "crp",
  "progress-bar": "crx",
  "progress-bar-text": "crw",
  "progress-active": "crN"
};
