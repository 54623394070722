export default {
  "iban": "qSz",
  "checkbox": "qSa",
  "toggle-content": "qSH",
  "beneficiary-name": "qSO",
  "category": "qSm",
  "beneficiary-email": "qSp",
  "beneficiary-form": "qSx",
  "beneficiary-email-container": "qSw"
};
