export default {
  "header-cell": "cWU",
  "header-content": "cWj caption-bold",
  "active": "cWW",
  "empty": "cWu",
  "align-right": "cuS",
  "request-type": "cuc",
  "requester": "cuq",
  "amount": "cuZ"
};
