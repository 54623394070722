export default {
  "referral-invite": "cnG",
  "referral": "cnr",
  "illustration": "cnb",
  "steps": "cny",
  "step": "cnU",
  "share-referral": "cnj",
  "choice": "cnW",
  "line": "cnu",
  "email": "ciS",
  "email-input": "cic",
  "input-field--error": "ciq",
  "error-message": "ciZ",
  "external-link-icon": "ciR"
};
