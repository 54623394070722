export default {
  "row": "cuN",
  "active": "cuv",
  "animated": "cuo",
  "fadein-item": "cun",
  "cell": "cui body-2",
  "cell-content": "cuA",
  "subtitle": "cuY caption",
  "no-padding": "cuh",
  "note": "cuf",
  "cell-amount": "cuK",
  "cell-content-amount": "cuG body-1"
};
