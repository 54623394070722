/* import __COLOCATED_TEMPLATE__ from './related-document.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import {
  EINVOICING_STATUS,
  INVOICING_STATUS_COLORS,
  STATUS,
} from 'qonto/constants/receivable-invoice';
import QuoteModel from 'qonto/models/quote';
import ReceivableCreditNoteModel from 'qonto/models/receivable-credit-note';
import ReceivableInvoiceModel from 'qonto/models/receivable-invoice';

interface RelatedDocumentSignature {
  // The arguments accepted by the component
  Args: {
    isReadOnly?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RelatedDocument extends Component<RelatedDocumentSignature> {
  @service declare intl: Services['intl'];

  tag = Tag;

  get modelName() {
    return (
      // @ts-expect-error
      this.args.document.get('constructor.modelName') ?? // @ts-expect-error
      this.args.document.get('content.constructor.modelName')
    );
  }

  get isRejected() {
    return [EINVOICING_STATUS.SUBMISSION_FAILED, EINVOICING_STATUS.REJECTED].includes(
      // @ts-expect-error
      this.args.document.get('einvoicingStatus')
    );
  }

  get showStatusBadge() {
    // @ts-expect-error
    return this.modelName === ReceivableInvoiceModel.modelName && !this.args.hideStatusBadge;
  }

  get amountStyle() {
    if (this.isRejected) {
      return 'rejected';
      // @ts-expect-error
    } else if (this.args.showSign) {
      // @ts-expect-error
      return this.args.document.get('amountDue') < 0 ? 'negative' : 'positive';
    }
  }

  get isInvoiceWithCreditNote() {
    return (
      this.modelName === ReceivableInvoiceModel.modelName &&
      this.creditNotes?.length > 0 &&
      // @ts-expect-error
      this.args.document.get('status') === STATUS.PAID
    );
  }

  get creditNotes() {
    // @ts-expect-error
    return this.args.document?.get('receivableCreditNotes');
  }

  get currency() {
    // @ts-expect-error
    return this.args.document.get('currency') || 'EUR';
  }

  get creditNotesAmount() {
    let sum = 0;
    if (this.creditNotes) {
      sum = this.creditNotes.reduce(
        // @ts-expect-error
        (accumulator, currentValue) => accumulator + Number(currentValue.amountDue),
        0
      );
    }

    return sum.toFixed(2);
  }

  get creditNotesAmountText() {
    // @ts-expect-error
    return this.intl.formatMoney(this.creditNotesAmount || 0, {
      currency: this.currency,
    });
  }

  get invoicedAmountText() {
    // @ts-expect-error
    return this.intl.formatMoney(this.args.document?.get('amountDue'), {
      currency: this.currency,
    });
  }

  get documentTitle() {
    switch (this.modelName) {
      case QuoteModel.modelName:
        return this.intl.t('receivable-invoice.quote-modal.related-documents.file.quote', {
          // @ts-expect-error
          QuoteNumber: this.args.document.get('number'),
        });
      case ReceivableInvoiceModel.modelName:
        // @ts-expect-error
        if (this.args.document.get('status') === STATUS.DRAFT) {
          return this.intl.t('receivable-invoices.quote-modal.related-documents.file.draft');
        }
        // @ts-expect-error
        if (this.args.document.get('isDeposit')) {
          return this.intl.t(
            'receivable-invoices.credit-note-modal.related-documents.file.deposit-invoice',
            {
              // @ts-expect-error
              depositInvoiceNumber: this.args.document.get('number'),
            }
          );
        }
        return this.intl.t('receivable-invoices.credit-note-modal.related-documents.file.invoice', {
          // @ts-expect-error
          invoiceNumber: this.args.document.get('number'),
        });
      case ReceivableCreditNoteModel.modelName:
        return this.intl.t('receivable-invoices.invoice-modal.related-documents.file.credit-note', {
          // @ts-expect-error
          creditNoteNumber: this.args.document.get('number'),
        });
      default:
        return '';
    }
  }

  get routeToRedirect() {
    switch (this.modelName) {
      case QuoteModel.modelName:
        return 'quotes.show';
      case ReceivableInvoiceModel.modelName:
        return 'receivable-invoices.show';
      case ReceivableCreditNoteModel.modelName:
        return 'receivable-invoices.credit-note-show';
      default:
        return null;
    }
  }

  get status() {
    // @ts-expect-error
    let status = this.args.document.get('status');
    // @ts-expect-error
    let displayedStatus = this.args.document.get('displayedStatus');

    return {
      displayedStatus: displayedStatus ?? status,
      // @ts-expect-error
      color: INVOICING_STATUS_COLORS[status],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::RelatedDocument': typeof RelatedDocument;
  }
}
