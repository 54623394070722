export default {
  "row": "cAs",
  "icon": "cAF",
  "cell": "cAg",
  "cell-amount": "cAD",
  "cell-status": "cAV",
  "cell-date": "cAL",
  "cell-content": "cAT",
  "align-right": "cAz"
};
