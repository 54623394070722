export default {
  "sidebar": "cNa",
  "body": "cNH",
  "form": "cNO",
  "form-section": "cNm",
  "form-title": "cNp title2",
  "row": "cNx",
  "payment-details": "cNw",
  "col": "cNN",
  "footer": "cNv",
  "footer-button": "cNo"
};
