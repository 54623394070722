export default {
  "attachment-viewer": "chn",
  "hide-sidebar": "chi",
  "sidebar": "chA",
  "header": "chY",
  "header-top": "chh",
  "header-icon": "chf",
  "header-amount": "chK",
  "infobox": "chG",
  "vat": "chr",
  "validation": "chb"
};
