export default {
  "referral-invite-new": "cnX",
  "referral": "cnC",
  "center": "cnk",
  "illustration": "cnJ",
  "share-referral": "cns",
  "choice": "cnF",
  "line": "cng",
  "email": "cnD",
  "email-input": "cnV",
  "input-field--error": "cnL",
  "error-message": "cnT",
  "external-link-icon": "cnz"
};
