export default {
  "header-cell": "cir",
  "header-content": "cib caption-bold",
  "active": "ciy",
  "empty": "ciU",
  "request-type": "cij",
  "date": "ciW",
  "requester": "ciu",
  "amount": "cAS",
  "header-status": "cAc"
};
