/* import __COLOCATED_TEMPLATE__ from './quick-actions-buttons.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';

interface QuickActionsButtonsSignature {
  // The arguments accepted by the component
  Args: {
    isApprovalRunning?: boolean;
    isRejectRunning?: boolean;
    isRequestLastStep?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class QuickActionsButtonsComponent extends Component<QuickActionsButtonsSignature> {
  spinner = Spinner;

  get isActionRunning() {
    return this.args.isApprovalRunning || this.args.isRejectRunning;
  }

  @action
  // @ts-expect-error
  stopPropagation(event) {
    event.stopPropagation();
  }

  /**
   *
   * @param {'approve' | 'reject'} type
   * @param {KeyboardEvent} event
   */
  @action
  // @ts-expect-error
  handleKeydown(type, event) {
    if (event.key !== 'Enter' && event.key !== ' ') {
      return;
    }

    if (type === 'approve') {
      // @ts-expect-error
      this.args.onApprove();
    } else {
      // @ts-expect-error
      this.args.onReject();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    QuickActionsButtons: typeof QuickActionsButtonsComponent;
  }
}
