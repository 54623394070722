export default {
  "row": "cHo",
  "cell": "cHn",
  "title": "cHi",
  "internal-note": "cHA",
  "unit": "cHY",
  "price": "cHh",
  "quick-actions": "cHf",
  "missing-details-container": "cHK",
  "missing-details-label": "cHG body-2",
  "warning-icon": "cHr",
  "empty": "cHb",
  "checkbox": "cHy",
  "align-checkbox": "cHU"
};
