export default {
  "fullscreen-modal": "cHS",
  "creation-form-modal": "cHc",
  "links-container": "cHq",
  "link-field": "cHZ",
  "link": "cHR",
  "add-link": "cHQ",
  "remove-link": "cHe",
  "flex-row": "cHB",
  "flex-item": "cHE",
  "unit-trigger": "cHd",
  "dynamic-placeholders": "cHI"
};
