export default {
  "header": "cjo",
  "header-type": "cjn",
  "close": "cji",
  "close-icon": "cjA",
  "header-infos": "cjY",
  "title": "cjh body-1",
  "declined": "cjf",
  "canceled": "cjK",
  "approved": "cjG",
  "date": "cjr body-2",
  "container": "cjb",
  "picto": "cjy",
  "request": "cjU",
  "picto-status": "cjj",
  "check": "cjW",
  "stop": "cju",
  "warning": "cWS",
  "general": "cWc",
  "amount": "cWq",
  "counterparty": "cWZ body-2",
  "initiator": "cWR",
  "initiator-avatar": "cWQ",
  "avatar-image": "cWe",
  "initiator-id": "cWB",
  "name": "cWE body-2",
  "role": "cWd caption-bold",
  "infobox": "cWI",
  "account-infobox": "cWt cWI",
  "beneficiary-warning": "cWM caption-bold",
  "dates-wrapper": "cWP",
  "dates": "cWl",
  "recurrence": "cWX caption-bold",
  "arrow": "cWC",
  "notify-checkbox": "cWk"
};
