export default {
  "wrapper": "ciQ",
  "tabs": "cie",
  "tabs-nav": "ciB",
  "tabs-panel-wrapper": "ciE",
  "list-head": "cid",
  "list": "ciI",
  "footer": "cit",
  "reward-amount": "ciM",
  "earned": "ciP",
  "pending": "cil",
  "empty-state-wrapper": "ciX",
  "empty-state": "ciC",
  "illustration": "cik",
  "error-wrapper": "ciJ",
  "error-illustration": "cis",
  "error-description": "ciF",
  "spinner": "cig",
  "total-earned": "ciD"
};
