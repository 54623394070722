export default {
  "container": "cwn",
  "text-content": "cwi",
  "text-wrapper": "cwA",
  "actions-container": "cwY",
  "illustration": "cwh",
  "feature-tour-link": "cwf",
  "link-illustration": "cwK",
  "lottie": "cwG"
};
