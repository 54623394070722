/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { EmptyStatesInform } from '@repo/domain-kit/pricing';

import { Header } from 'qonto/react/components/riba/table/header/header';
import { TableItem } from 'qonto/react/components/riba/table/item';

interface RibaTableSignature {
  Args: {
    payments: Array<any>;
    navigation: any;
    changePage: (page: number) => void;
    changePerPage: (perPage: number) => void;
    isLoading: boolean;
    sortBy: string;
    onSortByChanged: (sortBy: string) => void;
    hasNoResult: boolean;
  };
}

export default class RibaTable extends Component<RibaTableSignature> {
  Header = Header;
  TableItem = TableItem;
  EmptyStatesInform = EmptyStatesInform;
}
