export default {
  "row": "chB body-1",
  "active": "chE",
  "cell": "chd",
  "empty": "chI",
  "ellipsis": "cht",
  "text-secondary": "chM",
  "transfer-type": "chP",
  "transfer-type__icon": "chl",
  "quick-actions": "chX",
  "amount": "chC",
  "cell-approve": "chk",
  "account-select": "chJ",
  "row-compact": "chs",
  "cell-amount": "chF",
  "cell-content-receipt": "chg body-1",
  "cell-date": "chD",
  "row-sidebar": "chV",
  "hidden": "chL",
  "animated": "chT",
  "fadein-item": "chz",
  "animated-cell": "cha",
  "note": "chH",
  "checkbox": "chO"
};
