export default {
  "cell-content": "cbs",
  "icon": "cbF",
  "status_check-m": "cbg",
  "description": "cbD body-1",
  "from": "cbV body-2",
  "from--abort": "cbL",
  "description--abort": "cbT",
  "title": "cbz body-2"
};
