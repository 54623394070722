export default {
  "credit-note-sidebar": "cmr",
  "body": "cmb",
  "box": "cmy",
  "box-header": "cmU",
  "footer": "cmj",
  "footer-button": "cmW",
  "sidebar-box": "cmu",
  "border-top": "cpS",
  "row": "cpc",
  "related-invoice-title": "cpq caption-bold",
  "einvoice-rejected": "cpZ",
  "related-documents": "cpR"
};
