export default {
  "row": "cGZ",
  "active": "cGR",
  "dot": "cGQ",
  "red": "cGe",
  "green": "cGB",
  "yellow": "cGE",
  "status": "cGd",
  "align-right": "cGI",
  "animated": "cGt",
  "fadein-item": "cGM",
  "cell": "cGP body-2",
  "cell-content": "cGl",
  "amount": "cGX body-2",
  "subtitle": "cGC caption",
  "padding-left": "cGk",
  "padding-right": "cGJ",
  "note": "cGs"
};
