export default {
  "section-container": "cUv",
  "disclaimer-section": "cUo",
  "trip-title": "cUn caption-bold mb-16",
  "google-link-container": "cUi",
  "link-icon": "cUA",
  "trip-details": "cUY body-2",
  "trip-details-icon": "cUh",
  "trip-details-title": "cUf",
  "subtitle": "cUK caption-bold mb-16",
  "disclaimer": "cUG",
  "visualizer": "cUr"
};
