export default {
  "wrapper": "cna",
  "tabs": "cnH",
  "tabs-nav": "cnO",
  "tabs-panel": "cnm",
  "tabs-panel-wrapper": "cnp",
  "list-head": "cnx",
  "list": "cnw",
  "footer": "cnN",
  "reward-amount": "cnv",
  "rewarded": "cno",
  "registered": "cnn",
  "disclaimer": "cni",
  "empty-state-wrapper": "cnA",
  "empty-state": "cnY",
  "illustration": "cnh",
  "spinner": "cnf",
  "total-earned": "cnK"
};
