export default {
  "request-transfer-validation": "qca",
  "account-selector": "qcH",
  "has-error": "qcO",
  "actions": "qcm",
  "action-btn": "qcp",
  "warnings": "qcx",
  "top-border": "qcw",
  "account-selector-subtitle": "qcN",
  "action-btn-right": "qcv",
  "funds-disclaimer": "qco",
  "account-options": "qcn",
  "account-balance": "qci",
  "request-multi-transfer-validation": "qcA",
  "wrapper": "qcY",
  "warning-message-row": "qch",
  "multi-transfer-align": "qcf",
  "button-approve": "qcK",
  "button-approve-spinner": "qcG",
  "button-decline": "qcr"
};
