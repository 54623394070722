export default {
  "wrapper": "qSW",
  "beneficiary": "qSu",
  "name": "qcS body-1",
  "beneficiary-email": "qcc body-2",
  "dropdown-icon": "qcq",
  "trusted": "qcZ",
  "account-number": "qcR flex body-2",
  "account-icon": "qcQ",
  "m-chip": "qce",
  "dropdown": "qcB body-2",
  "delete-option": "qcE",
  "beneficiary-name": "qcd"
};
