export default {
  "sections": "qSf",
  "actions": "qSK",
  "search-input": "qSG",
  "no-upload": "qSr",
  "new-link": "qSb body-2",
  "between-links": "qSy",
  "action-bar": "qSU",
  "empty-state": "qSj"
};
