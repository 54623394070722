export default {
  "subtitle": "cGb",
  "green-text": "cGy",
  "balance-subtitle": "cGU",
  "options": "cGj",
  "shadow": "cGW",
  "menu-item": "cGu body-2",
  "close-account": "crS",
  "footer": "crc",
  "progress": "crq",
  "progress-bar": "crZ",
  "progress-bar-text": "crR",
  "progress-active": "crQ"
};
