/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox, Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { MileageTableCell } from 'qonto/react/components/mileage/table/cell';

interface ReimbursementsTableItemPendingSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
    isApproveCompactView?: boolean;
    isSidepanelOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class ReimbursementsTableItemPendingComponent extends Component<ReimbursementsTableItemPendingSignature> {
  checkbox = Checkbox;

  mileageTableCell = MileageTableCell;
  spinner = Spinner;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare modals: Services['modals'];
  @service declare attachmentsManager: Services['attachmentsManager'];
  @service declare notifierCounterManager: Services['notifierCounterManager'];
  @service declare router: Services['router'];

  get requestAmount() {
    // @ts-expect-error
    let { request } = this.args;
    switch (request.requestType) {
      case 'mileage':
        return { value: request.amount.value, currency: request.amount.currency };
      case 'expense_report': {
        return request.amount;
      }
    }
  }

  get mainAccount() {
    // @ts-expect-error
    return this.args.request.bankAccount ?? this.organizationManager.currentAccount;
  }

  get rejectTooltipText() {
    return this.isRequestedByCurrentUser
      ? this.intl.t('reimbursements.tooltip.cancel')
      : this.intl.t('reimbursements.tooltip.reject');
  }

  get isRequestedByCurrentUser() {
    let currentUserId = this.organizationManager.membership.id;
    // @ts-expect-error
    let initiatorId = this.args.request.get?.('initiator.id') || this.args.request.initiator.id;

    return currentUserId === initiatorId;
  }

  @action
  // @ts-expect-error
  stopPropagation(event) {
    event.stopPropagation();
  }

  @action
  // @ts-expect-error
  onBankAccountSelect(account) {
    // @ts-expect-error
    this.args.onBankAccountSelect(this.args.request, account);
  }

  @action
  // @ts-expect-error
  onBankAccountSelectClick(event) {
    this.segment.track('bank_account_picker_opened', {
      origin: 'reimbursements',
    });

    event.stopPropagation();
  }

  @action
  // @ts-expect-error
  onRowClick(id) {
    if (this.onRequestApprove.isRunning || this.onRequestReject.isRunning) {
      return;
    }

    // @ts-expect-error
    if (this.args.selectedItemIds?.length > 0) {
      return;
    }

    // @ts-expect-error
    this.args.highlightItem(id);
  }

  onRequestReject = dropTask(async () => {
    // @ts-expect-error
    await this.args.onRequestReject(this.args.request);
    await this.notifierCounterManager.updateCounter();
  });

  onRequestApprove = dropTask(async () => {
    // @ts-expect-error
    if (!this.args.request.bankAccount) {
      // @ts-expect-error
      this.args.request.bankAccount = this.mainAccount;
    }
    // @ts-expect-error
    await this.args.onRequestApprove(this.args.request);
    await this.notifierCounterManager.updateCounter();
  });

  handleOpenFilePreviewModal = dropTask(async event => {
    event.stopPropagation();
    // @ts-expect-error
    await this.args.request.get('attachments');

    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      // @ts-expect-error
      selectedFile: this.args.request.attachmentsFiles[0],
      // @ts-expect-error
      model: this.args.request,
      download: this.attachmentsManager.downloadAttachment,
    });
  });

  get showAttachment() {
    // @ts-expect-error
    return this.args.request.attachmentIds?.length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Pending::Table::Item': typeof ReimbursementsTableItemPendingComponent;
  }
}
