/* import __COLOCATED_TEMPLATE__ from './invoice-number-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { importAsset } from 'qonto/helpers/import-asset';

const NUMBERING_PREVIEW_IMAGES = {
  de: '/illustrations/receivable-invoices/invoice-number-preview-static-de.svg',
  en: '/illustrations/receivable-invoices/invoice-number-preview-static-en.svg',
  es: '/illustrations/receivable-invoices/invoice-number-preview-static-es.svg',
  fr: '/illustrations/receivable-invoices/invoice-number-preview-static-fr.svg',
  it: '/illustrations/receivable-invoices/invoice-number-preview-static-en.svg',
};

interface InvoiceNumberFormSignature {
  // The arguments accepted by the component
  Args: {
    isEdit?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceNumberFormComponent extends Component<InvoiceNumberFormSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare errors: Services['errors'];
  @service declare segment: Services['segment'];
  @service declare localeManager: Services['localeManager'];

  @tracked displayErrorMessage = false;

  constructor(owner: unknown, args: InvoiceNumberFormSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.oldNumberingPattern = this.config.numberingPattern;
    // @ts-expect-error
    this.oldNextNumber = this.config.nextNumber;
  }

  get config() {
    // @ts-expect-error
    return this.args.config;
  }

  get nextInvoiceNumber() {
    let prefix = this.config.numberingPattern.replace(
      /\(YYYY\)|\(AAAA\)|\(JJJJ\)/g,
      new Date().getFullYear()
    );

    return prefix + this.config.nextNumber;
  }

  get previewImageUrl() {
    // @ts-expect-error
    return importAsset([NUMBERING_PREVIEW_IMAGES[this.localeManager.locale]]);
  }

  // @ts-expect-error
  @action updateNextNumber(number) {
    this.config.nextNumber = number;
    this.config.nextNumber = number.replace(/[^0-9]/g, '');
  }

  saveAutomaticSettingsTask = dropTask(async () => {
    this.config.numberingMode = 'automatic';

    this.segment.track('invoice_number-activation-screen_numbering-method-chosen', {
      method: 'automatic',
    });

    await this.saveOrganizationSettingsTask.perform();
  });

  saveManualSettingsTask = dropTask(async () => {
    this.config.numberingMode = 'manual';
    // @ts-expect-error
    this.config.numberingPattern = this.oldNumberingPattern;
    // @ts-expect-error
    this.config.nextNumber = this.oldNextNumber;

    this.segment.track('invoice_number-activation-screen_numbering-method-chosen', {
      method: 'manual',
    });

    await this.saveOrganizationSettingsTask.perform();
  });

  saveOrganizationSettingsTask = dropTask(async () => {
    await this.config.validate();
    let isNextNumberValid = this.config.validations.attrs.nextNumber.isValid;
    this.displayErrorMessage = !isNextNumberValid;

    if (!isNextNumberValid) {
      return;
    }

    try {
      await this.config.save();
      // @ts-expect-error
      this.args.transitionToNext?.();
      // @ts-expect-error
      this.args.save?.();
    } catch (error) {
      this.errors.handleError(error);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::InvoiceNumberForm': typeof InvoiceNumberFormComponent;
  }
}
