/* import __COLOCATED_TEMPLATE__ from './invoice-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DatePicker, Disclaimer, TextAreaField } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { equal } from 'macro-decorators';

// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import {
  CREATE_DEPOSIT_INVOICE_FROM_NEW_INVOICE_DISCOVERABILITY_STORAGE_KEY,
  STATUS,
} from 'qonto/constants/receivable-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { defaultValues } from 'qonto/models/receivable-invoice/item';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { DepositInvoiceToggle } from 'qonto/react/components/receivable-invoices/form/deposit-invoice-toggle/deposit-invoice-toggle';
import transformKeys from 'qonto/utils/transform-keys';

import { COMMON_CREDIT_PERIODS, COMMON_QUOTE_EXPIRY_PERIODS } from './due-date-selector';

interface InvoiceDetailsSignature {
  // The arguments accepted by the component
  Args: {
    isQuote?: boolean;
    isDEInvoicing?: boolean;
    isFirstDocument?: boolean;
    isEInvoicing?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceDetailsComponent extends Component<InvoiceDetailsSignature> {
  disclaimerBlock = Disclaimer.Block;
  textAreaField = TextAreaField;
  datePicker = DatePicker;
  depositInvoiceToggle = DepositInvoiceToggle;
  instructionalTooltip = InstructionalTooltip;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare store: Services['store'];

  @tracked showHeaderTextField = false;
  @tracked isInstructionalTooltipDismissed = false;

  // @ts-expect-error
  cachedInvoiceItems = this.args.document.items.map(item =>
    transformKeys(item.serialize().data.attributes)
  );

  // @ts-expect-error
  @equal('args.document.status', STATUS.DRAFT) isDraft;

  // @ts-expect-error
  selectedPeriod;

  get origin() {
    // storing the current route, to be used when transitioning to the invoicing-settings route
    return this.router?.currentRoute;
  }

  get formOrigin() {
    // storing params if any (document id), to be used when transitioning to the invoicing-settings route
    let { currentRoute } = this.router;
    let params = currentRoute?.params?.id;

    // when transitioning to the invoicing-settings route, the query obj will contained the origin route
    // as the transition.from is not always available
    return { origin: this.origin?.name, params };
  }

  get detailsTitle() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.invoice-details.title');
    }
    return this.intl.t('receivable-invoices.invoice-creation.invoice-details.title');
  }

  get automaticNumberLabel() {
    if (this.args.isQuote) {
      return this.intl.t(
        'receivable-invoices.quote-creation.invoice-details.automatic-invoice-number.placeholder'
      );
    }
    return this.intl.t(
      'receivable-invoices.invoice-creation.invoice-details.automatic-invoice-number.label'
    );
  }

  get manualNumberLabel() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.invoice-details.quote-number.label');
    }
    return this.intl.t('receivable-invoices.invoice-creation.invoice-details.invoice-number.label');
  }

  get manualNumberPlaceholder() {
    if (this.args.isQuote) {
      return this.intl.t(
        'receivable-invoices.quote-creation.invoice-details.quote-number.placeholder'
      );
    }
    return this.intl.t(
      'receivable-invoices.invoice-creation.invoice-details.invoice-number.placeholder'
    );
  }

  get automaticNumber() {
    return this.args.isQuote
      ? // @ts-expect-error
        this.args.settings?.quoteNextNumberFormatted
      : // @ts-expect-error
        this.args.settings?.invoiceNextNumberFormatted;
  }

  get numberErrorMessage() {
    // @ts-expect-error
    let errors = this.args.document.errors.get('number') || [];
    if (errors.length === 0) return null;
    if (
      errors.some(
        // @ts-expect-error
        e =>
          e.message === 'invoice_number_already_exists' ||
          e.message === 'quote_number_already_exists' ||
          e.message === 'invalid'
      )
    ) {
      // @ts-expect-error
      if (this.args.settings?.numberingMode === 'automatic') {
        if (this.args.isQuote) {
          return this.intl.t(
            'receivable-invoices.quote-creation.invoice-details.automatic-invoice-number.try-again-error'
          );
        }

        return this.intl.t(
          'receivable-invoices.invoice-creation.invoice-details.automatic-invoice-number.try-again-error'
        );
      }
      if (this.args.isQuote) {
        return this.intl.t(
          'receivable-invoices.quote-creation.invoice-settings.numbering.try-again-error'
        );
      }

      if (errors[0].message === 'invalid') {
        return this.intl.t('self-billing.errors.invoice-numbering.pattern-conflict');
      }

      return this.intl.t(
        'receivable-invoices.invoice-creation.invoice-settings.numbering.try-again-error'
      );
    }
    return this.intl.t('receivable-invoices.invoice-creation.errors.required-field');
  }

  get issueDate() {
    // @ts-expect-error
    return this.args.document.issueDate ? parseDate(this.args.document.issueDate) : null;
  }

  get showPerformanceDate() {
    return !this.args.isQuote;
  }

  get performanceDate() {
    // @ts-expect-error
    return this.args.document.performanceDate
      ? // @ts-expect-error
        parseDate(this.args.document.performanceDate)
      : null;
  }

  get performanceStartDate() {
    // @ts-expect-error
    return this.args.document.performanceStartDate
      ? // @ts-expect-error
        parseDate(this.args.document.performanceStartDate)
      : null;
  }

  get performanceEndDate() {
    // @ts-expect-error
    return this.args.document.performanceEndDate
      ? // @ts-expect-error
        parseDate(this.args.document.performanceEndDate)
      : null;
  }

  get performanceValidationMessages() {
    return {
      valueMissing: this.intl.t(
        'receivable-invoices.invoice-creation.errors.performance-date.start-date.required-field'
      ),
      rangeUnderflow: this.intl.t(
        'receivable-invoices.invoice-creation.errors.performance-date.end-date.min-date'
      ),
    };
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get performanceDateLabel() {
    if (this.legalCountry === 'DE') {
      return this.intl.t(
        'receivable-invoices.invoice-creation.invoice-details.performance-date.de-label'
      );
    }
    return this.intl.t(
      'receivable-invoices.invoice-creation.invoice-details.performance-date.label'
    );
  }

  get isPerformanceDateClearable() {
    if (this.legalCountry === 'DE') {
      return false;
    }
    return true;
  }

  get dueOrExpiryDateLabel() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.invoice-details.expiry-date.label');
    }
    return this.intl.t('receivable-invoices.invoice-creation.invoice-details.due-date.label');
  }

  get displayHeaderTextFieldContainer() {
    return !this.args.isDEInvoicing;
  }

  get shouldShowHeader() {
    // @ts-expect-error
    return this.args.document.header ? this.args.document.header : this.showHeaderTextField;
  }

  get displayDepositInvoiceToggle() {
    let quoteHasNoActiveRelatedInvoices =
      // @ts-expect-error
      !this.args.document.quote
        ?.get('receivableInvoices')
        // @ts-expect-error
        ?.find(({ status, isNew }) => !isNew && status !== STATUS.CANCELED);

    return (
      !this.args.isQuote &&
      // @ts-expect-error
      !this.args.document.depositInvoice &&
      quoteHasNoActiveRelatedInvoices
    );
  }

  get shouldDisplayInstructionalTooltip() {
    let isGermanOrganization = this.legalCountry === 'DE';
    return (
      !isGermanOrganization &&
      !safeLocalStorage.getItem(CREATE_DEPOSIT_INVOICE_FROM_NEW_INVOICE_DISCOVERABILITY_STORAGE_KEY)
    );
  }

  @action
  handleDismissInstructionalTooltip() {
    safeLocalStorage.setItem(
      CREATE_DEPOSIT_INVOICE_FROM_NEW_INVOICE_DISCOVERABILITY_STORAGE_KEY,
      // @ts-expect-error
      true
    );
    this.isInstructionalTooltipDismissed = true;
  }

  @action
  // @ts-expect-error
  onPeriodChange(period) {
    this.selectedPeriod = period;
  }

  @action
  // @ts-expect-error
  onIsDepositChange(isDeposit) {
    this.segment.track('invoice_deposit-toggle', { type: isDeposit ? 'ON' : 'OFF' });
    // @ts-expect-error
    this.args.document.isDeposit = isDeposit;

    if (isDeposit) {
      // @ts-expect-error
      this.args.document.discount = null;
      // @ts-expect-error
      this.args.document.discountAmount = null;
    }

    // @ts-expect-error
    if (!this.args.document.quote.id) {
      this.#handleFromInvoiceDepositToggleChange(isDeposit);
    } else {
      this.#handleFromQuoteDepositToggleChange(isDeposit);
    }
  }

  // @ts-expect-error
  @action updateNumber(number) {
    // @ts-expect-error
    this.args.document.number = number;
    // @ts-expect-error
    this.args.document.number = number.replace(/[^\w/\-?:().,'+]/g, '');
  }

  // @ts-expect-error
  @action updateIssueDate(newIssueDate) {
    if (this.args.isQuote) {
      return this._updateQuoteIssueDate(newIssueDate?.toString());
    }
    return this._updateInvoiceIssueDate(newIssueDate?.toString());
  }

  // @ts-expect-error
  @action updatePerformanceDate(date) {
    // @ts-expect-error
    this.args.document.performanceDate = date?.toString();

    if (date) {
      this.segment.track('invoice_performance-date', { action: 'filled' });
    } else if (date === null) {
      this.segment.track('invoice_performance-date', { action: 'cleared' });
    }
  }

  // @ts-expect-error
  @action updatePerformanceStartDate(date) {
    // @ts-expect-error
    this.args.document.performanceStartDate = date?.toString();

    if (date) {
      this.segment.track('invoice_performance-start-date', { action: 'filled' });
    } else if (date === null) {
      this.segment.track('invoice_performance-start-date', { action: 'cleared' });
    }
  }

  // @ts-expect-error
  @action updatePerformanceEndDate(date) {
    // @ts-expect-error
    this.args.document.performanceEndDate = date?.toString();

    if (date) {
      this.segment.track('invoice_performance-end-date', { action: 'filled' });
    } else if (date === null) {
      this.segment.track('invoice_performance-end-date', { action: 'cleared' });
    }
  }

  @action
  handleClickShowHeaderTextField() {
    this.showHeaderTextField = true;
    this.segment.track('invoice_header-message', { action: 'add' });
  }

  @action
  handleCloseHeaderTextField() {
    this.showHeaderTextField = false;
    // @ts-expect-error
    this.args.document.header = '';
    this.segment.track('invoice_header-message', { action: 'close' });
  }

  // @ts-expect-error
  _updateQuoteIssueDate(newIssueDate) {
    // @ts-expect-error
    let { expiryDate } = this.args.document;
    let isCommonExpiryPeriod = COMMON_QUOTE_EXPIRY_PERIODS.includes(this.selectedPeriod);

    if (!dayjs(newIssueDate).isValid()) {
      // @ts-expect-error
      this.args.document.issueDate = undefined;
      return;
    }

    if (!isCommonExpiryPeriod && dayjs(newIssueDate).isAfter(expiryDate, 'day')) {
      // @ts-expect-error
      this.args.document.expiryDate = undefined;
    }

    if (isCommonExpiryPeriod) {
      // @ts-expect-error
      this.args.document.expiryDate = dayjs(newIssueDate)
        .add(this.selectedPeriod, 'day')
        .format(DATE_PICKER_FIELD_FORMAT);
    }

    // @ts-expect-error
    this.args.document.issueDate = newIssueDate;
  }

  // @ts-expect-error
  _updateInvoiceIssueDate(newIssueDate) {
    // @ts-expect-error
    let { dueDate } = this.args.document;
    let isCommonCreditPeriod = COMMON_CREDIT_PERIODS.includes(this.selectedPeriod);

    if (!dayjs(newIssueDate).isValid()) {
      // @ts-expect-error
      this.args.document.issueDate = undefined;
      return;
    }

    if (!isCommonCreditPeriod && dayjs(newIssueDate).isAfter(dueDate, 'day')) {
      // @ts-expect-error
      this.args.document.dueDate = undefined;
    }

    if (isCommonCreditPeriod) {
      // @ts-expect-error
      this.args.document.dueDate = dayjs(newIssueDate)
        .add(this.selectedPeriod, 'day')
        .format(DATE_PICKER_FIELD_FORMAT);
    }

    // @ts-expect-error
    this.args.document.issueDate = newIssueDate;
  }

  // @ts-expect-error
  #handleFromInvoiceDepositToggleChange(isDeposit) {
    // @ts-expect-error
    let switchingBackToOriginalValue = !this.args.document.changedAttributes().isDeposit;

    if (isDeposit) {
      // @ts-expect-error
      let [depositItem, ...otherItems] = this.args.document.items;
      // @ts-expect-error
      otherItems.forEach(item => {
        item.unloadRecord();
      });

      if (switchingBackToOriginalValue) {
        depositItem.setProperties({ ...this.cachedInvoiceItems[0] });
      }
    } else {
      // @ts-expect-error
      this.args.document.items.forEach(item => item.unloadRecord());
      if (this.isDraft && switchingBackToOriginalValue) {
        // @ts-expect-error
        this.cachedInvoiceItems.forEach(cachedItem => {
          this.store.createRecord('receivable-invoice/item', {
            ...cachedItem,
            // @ts-expect-error
            receivableInvoice: this.args.document,
          });
        });
      } else {
        // @ts-expect-error
        this.args.document.items.push(
          // @ts-expect-error
          this.store.createRecord('receivable-invoice/item', defaultValues(this))
        );
      }
    }
  }

  // @ts-expect-error
  #handleFromQuoteDepositToggleChange(isDeposit) {
    // @ts-expect-error
    let [, ...otherItems] = this.args.document.items;
    if (isDeposit) {
      // @ts-expect-error
      otherItems.forEach(item => {
        item.unloadRecord();
      });
    } else {
      // @ts-expect-error
      this.args.document.discount = this.args.document.get('quote.discount');
      // @ts-expect-error
      this.args.document.discountAmount = this.args.document.get('quote.discountAmount');
      // @ts-expect-error
      this.args.document.items.forEach(item => item.unloadRecord());
      // @ts-expect-error
      this.args.document.get('quote.items').forEach(item => {
        let {
          title,
          description,
          quantity,
          unitPrice,
          vatRate,
          vatExemptionCode,
          discount,
          unit,
          productId,
          links,
          type,
        } = item;
        this.store.createRecord('receivable-invoice/item', {
          title,
          description,
          quantity,
          unitPrice,
          vatRate,
          vatExemptionCode,
          discount,
          unit,
          productId,
          links,
          type,
          // @ts-expect-error
          receivableInvoice: this.args.document,
        });
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::InvoiceDetails': typeof InvoiceDetailsComponent;
  }
}
