export default {
  "card-details-container": "cbr",
  "title": "cbb caption-bold mb-16",
  "section": "cby",
  "divider": "cbU",
  "top-divider": "cbj",
  "content": "cbW body-2",
  "description": "cbu",
  "justify-end": "cyS",
  "icon": "cyc ml-8"
};
