/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox } from '@repo/design-system-kit';

import { Wallet } from 'qonto/react/assets/icons';
import type { Amount } from 'qonto/react/models/amount';
import type { Services } from 'qonto/services/top-banners/config-types';

interface RibaTasksTableItemSignature {
  Args: {
    item: {
      id: string;
      creditorName: string;
      reference: string;
      dueDate: string;
      amount: Amount;
    };
    onSelectItem: (id: string, value: boolean) => void;
    isSelected: boolean;
  };
  Element: HTMLTableRowElement;
}

export default class RibaTasksTableItem extends Component<RibaTasksTableItemSignature> {
  Checkbox = Checkbox;
  Wallet = Wallet;
  @service declare organizationManager: Services['organizationManager'];

  @tracked
  // @ts-expect-error
  bankAccount = this.organizationManager.organization.mainAccount;

  get activeAccounts() {
    return this.organizationManager.organization.activeAccounts;
  }

  get hasSingleBankAccount() {
    return this.activeAccounts.length === 1;
  }

  approveRequestTask() {
    // Implement approval logic here
  }

  rejectRequestTask() {
    // Implement rejection logic here
  }

  @action
  selectPayment() {
    this.args.onSelectItem(this.args.item.id, !this.args.isSelected);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Riba::TasksTable::Item': typeof RibaTasksTableItem;
  }
}
