/* import __COLOCATED_TEMPLATE__ from './customer-card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, Disclaimer } from '@repo/design-system-kit';

import CURRENCIES from 'qonto/constants/currencies';
import {
  DEFAULT_LOCALE_PER_COUNTRY,
  EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY,
  STATUS,
} from 'qonto/constants/receivable-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';

interface ReceivableInvoicesCustomerCardSignature {
  // The arguments accepted by the component
  Args: {
    isCustomerInvalid?: boolean;
    isFirstFrenchEinvoice?: boolean;
    isEinvoice?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesCustomerCard extends Component<ReceivableInvoicesCustomerCardSignature> {
  disclaimerInline = Disclaimer.Inline;
  badge = Badge;
  instructionalTooltip = InstructionalTooltip;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];
  @service declare userManager: Services['userManager'];

  @tracked isEinvoiceTooltipDismissed = false;

  get currentUserId() {
    // @ts-expect-error
    return this.userManager.currentUser?.id;
  }

  get isCustomerInvalidWithoutError() {
    // @ts-expect-error
    return this.args.isCustomerInvalid && !this.args.hasError;
  }

  get isOrganizationCountryDifferentThanCustomerCountry() {
    return (
      this.organizationManager.organization.legalCountry !==
      // @ts-expect-error
      this.args.customer.get('billingAddress.countryCode')
    );
  }

  get billingStreetAddress() {
    // @ts-expect-error
    let streetAddress = this.args.customer.get('billingAddress.streetAddress');
    streetAddress = this.hasSomeBillingAddressInfo ? streetAddress + ', ' : streetAddress;
    return streetAddress;
  }

  get billingAddressInfo() {
    // @ts-expect-error
    let zipCode = this.args.customer.get('billingAddress.zipCode');
    // @ts-expect-error
    let city = this.args.customer.get('billingAddress.city');
    // @ts-expect-error
    let countryCode = this.args.customer.get('billingAddress.countryCode');

    // return full string if all three are present
    if (this.hasAllBillingAddressInfo) {
      return `${zipCode} ${city}, ${countryCode}`;
    }

    // return the string with a space or comma depending
    // on the combination of at least two values
    if (zipCode && city) {
      return `${zipCode} ${city}`;
    }
    if (zipCode && countryCode) {
      return `${zipCode}, ${countryCode}`;
    }
    if (city && countryCode) {
      return `${city}, ${countryCode}`;
    }

    // this will either return an empty string or one of
    // these values if at least one exists
    return zipCode + city + countryCode;
  }

  get hasAllBillingAddressInfo() {
    return (
      // @ts-expect-error
      this.args.customer.get('billingAddress.zipCode') &&
      // @ts-expect-error
      this.args.customer.get('billingAddress.city') && // @ts-expect-error
      this.args.customer.get('billingAddress.countryCode')
    );
  }

  get hasSomeBillingAddressInfo() {
    return (
      // @ts-expect-error
      this.args.customer.get('billingAddress.zipCode') ||
      // @ts-expect-error
      this.args.customer.get('billingAddress.city') || // @ts-expect-error
      this.args.customer.get('billingAddress.countryCode')
    );
  }

  // 4th row for language and currency info is shown if one of them is not the default one
  get showLanguageAndCurrency() {
    let orgaCountry = this.organizationManager.organization.legalCountry;
    return (
      // @ts-expect-error
      this.args.customer.get('locale') !== DEFAULT_LOCALE_PER_COUNTRY[orgaCountry] ||
      // @ts-expect-error
      this.args.customer.get('currency') !== CURRENCIES.default ||
      this.isOrganizationCountryDifferentThanCustomerCountry || // @ts-expect-error
      this.args.showFullCustomerInfo
    );
  }

  // French & German E-invoicing
  get shouldDisplayEinvoiceTooltip() {
    let storageValue = safeLocalStorage.getItem(
      `${EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY}-${this.currentUserId}`
    );

    let { organization } = this.organizationManager;

    switch (organization?.legalCountry) {
      case 'DE':
        return (
          Boolean(!storageValue) &&
          !this.isEinvoiceTooltipDismissed &&
          // @ts-expect-error
          this.args.documentStatus === STATUS.UNPAID
        );
      case 'FR':
        return (
          this.args.isFirstFrenchEinvoice &&
          this.args.isEinvoice &&
          !this.isEinvoiceTooltipDismissed &&
          Boolean(!storageValue)
        );
      default:
        return false;
    }
  }

  get einvoiceFaqLink() {
    let { organization } = this.organizationManager;

    switch (organization?.legalCountry) {
      case 'DE':
        return this.zendeskLocalization.getLocalizedArticle('de-einvoice');
      case 'FR':
        return this.intl.t('receivable-invoices.customer-card.einvoicing-disclaimer-faq.url', {
          faqUrl: this.zendeskLocalization.getLocalizedArticle('fr-einvoice'),
        });
      default:
        return false;
    }
  }

  @action
  handleEinvoiceTooltipClose() {
    this.isEinvoiceTooltipDismissed = true;

    safeLocalStorage.setItem(
      `${EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY}-${this.currentUserId}`,
      // @ts-expect-error
      true
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CustomerCard': typeof ReceivableInvoicesCustomerCard;
  }
}
