export default {
  "gap-32": "cpv",
  "fieldset": "cpo",
  "date-picker": "cpn",
  "with-performance-date": "cpi",
  "mt-4": "cpA",
  "clients": "cpY",
  "dropdown-option": "cph",
  "dropdown-content": "cpf",
  "select-client": "cpK",
  "client-option": "cpG",
  "icon-tooltip": "cpr",
  "payment-details": "cpb",
  "dates-container": "cpy",
  "automatic-number-label": "cpU",
  "label": "cpj",
  "numbering-container": "cpW",
  "fix-width": "cpu",
  "customer-error": "cxS",
  "amount-text": "cxc",
  "header-text-field-container": "cxq",
  "header-text-field": "cxZ",
  "header-text-field-close-button": "cxR",
  "mt-16": "cxQ"
};
