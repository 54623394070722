/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached, tracked } from '@glimmer/tracking';

import { AmountField, NumberField, TextAreaField } from '@repo/design-system-kit';
import { dropTask, rawTimeout, restartableTask, timeout } from 'ember-concurrency';

import { SDD_INDIVIDUAL_COLLECTION_LIMIT } from 'qonto/constants/direct-debit-collections';
import {
  DEFAULT_PAYMENT_CONDITION,
  DEFAULT_PAYMENT_METHOD,
} from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import type InvoiceSubscriptionModel from 'qonto/models/invoice-subscription';
import type QuoteModel from 'qonto/models/quote';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice';
import type ReceivableInvoiceItemModel from 'qonto/models/receivable-invoice/item';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import normalize from 'qonto/utils/normalize-string';
// @ts-expect-error
import { mapInvoicingItemToProduct } from 'qonto/utils/products';
// @ts-expect-error
import { getSupportedUnits, getVatExemptionReasons } from 'qonto/utils/receivable-invoicing';

// @ts-expect-error
import styles from './item.module.css';

const UNIT_PRICE_LIMIT = 1000000000;
const VAT_RATE_PRECISION = 0.01;
const MAX_TITLE_LENGTH = 120;
const MAX_UNIT_PRICE_LENGTH_BEFORE_SEPARATOR = 9;

interface ReceivableInvoicesFormItemSignature {
  // The arguments accepted by the component
  Args: {
    isDisabled?: boolean;
    isQuote?: boolean;
    isCreditNote?: boolean;
    document: ReceivableInvoiceModel | QuoteModel | InvoiceSubscriptionModel;
    item: ReceivableInvoiceItemModel;
    onVatResetDisclaimerDismiss?: (value: boolean) => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ReceivableInvoicesFormItemComponent extends Component<ReceivableInvoicesFormItemSignature> {
  amountField = AmountField;
  textAreaField = TextAreaField;
  numberField = NumberField;

  styles = styles;

  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare modals: Services['modals'];
  @service declare store: Services['store'];
  @service declare productsCatalog: Services['productsCatalog'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked showDiscountField = false;
  @tracked hasDropdownUnitBeenSelected = false;
  @tracked unitPriceInputValue = this.args.item.unitPrice;
  @tracked quantityInputValue = this.args.item.quantity;

  @tracked unitList = this.supportedUnits;
  // @ts-expect-error
  @tracked selectedUnit;
  @tracked lastSelectionStamp = Date.now();

  constructor(owner: unknown, args: ReceivableInvoicesFormItemSignature['Args']) {
    super(owner, args);
    this.showDiscountField = Boolean(this.args.item.discount);
  }

  get vatRatePercentage() {
    if (this.args.item.vatRate === null || this.args.item.vatRate === undefined) return '';
    return String(Number(this.args.item.vatRate) * 100).replace(/[,.]/g, this.decimalSeparator);
  }

  get showItemHistory() {
    return !this.args.isDisabled;
  }

  get isGermanQuote() {
    // @ts-expect-error
    return this.args.organization?.legalCountry === 'DE' && this.args.isQuote;
  }

  get priceLimit() {
    return UNIT_PRICE_LIMIT;
  }

  get products() {
    if (this.productsCatalog.fetchProductsTask.isRunning) {
      // This is a workaround to avoid having to use a promise for the power select loading state.
      return [{}];
    }
    return this.productsCatalog.fetchProductsTask.lastSuccessful?.value || [];
  }

  get supportedUnits() {
    return getSupportedUnits(this.intl);
  }

  get isUnitCustom() {
    return this.args.item.unit && !this.selectedUnit?.unitCode;
  }

  trackItemSelectEvent = dropTask(async () => {
    this.segment.track('invoice_item-title_dropdown_selected');
    await rawTimeout(60000);
  });

  onTitleChangeTask = restartableTask(async value => {
    this.args.item.title = value;
    await timeout(DEBOUNCE_MS);
    this.productsCatalog.fetchProductsTask
      .perform({ sort_by: 'title:asc,unit_price.value', query: value })
      .catch(ignoreCancelation);
  });

  @action
  loadOptions() {
    this.productsCatalog.fetchProductsTask
      .perform({
        sort_by: 'title:asc,unit_price.value',
        query: this.args.item.title,
      })
      .catch(ignoreCancelation);
  }

  @action
  // @ts-expect-error
  onFocusOut(isFocused) {
    if (!isFocused) {
      this.args.item.description = this.args.item.description?.replace(/\n{1,}/g, '\n');
      this.trackDescriptionChange.perform().catch(ignoreCancelation);
    }
  }

  @action
  // @ts-expect-error
  handleUnitSelection(unit) {
    this.args.item.unit = unit.unitCode;
    this.selectedUnit = unit;

    // the event will be recorded only one time per item each time the form will be opened
    if (!this.hasDropdownUnitBeenSelected) {
      this.segment.track('invoice-creation_item_unit');
      this.hasDropdownUnitBeenSelected = true;
    }
  }

  @action
  // @ts-expect-error
  handleUnitInputChange(value) {
    this.args.item.unit = value;
    // @ts-expect-error
    this.unitList = this.supportedUnits.filter(unit =>
      // @ts-expect-error
      unit.keywords.find(word => normalize(word).includes(normalize(value)))
    );
    // @ts-expect-error
    this.selectedUnit = this.unitList.find(unit => unit.unitCode === value);
  }

  @action
  onUnitInputClose() {
    this.unitList = this.supportedUnits;
    // @ts-expect-error
    this.selectedUnit = this.unitList.find(unit => unit.unitCode === this.args.item.unit);

    // Do not show the dropdown if the unit is custom
    if (this.isUnitCustom) {
      this.unitList = [];
    }
  }

  trackDescriptionChange = dropTask(async () => {
    if (this.args.item.description) {
      this.segment.track('invoice_item-description_filled');
      await rawTimeout(60000);
    }
  });

  @action
  // @ts-expect-error
  onItemSelect(item, shouldTrack = true) {
    if (this.productsCatalog.fetchProductsTask.isRunning) return;

    /**
     * lastSelectionStamp is used as a key for TextAreaField,
     * to force the re-render when an item is selected
     * */
    this.lastSelectionStamp = Date.now();
    let { description, title, unit, unitPrice, vatRate, vatExemptionCode, links, type, id } = item;
    this.args.item.description = description;
    this.args.item.title = title;
    this.args.item.unit = unit;

    let { legalCountry } = this.organizationManager.organization;
    let customerCountry = this.args.document?.customer?.get('billingAddress.countryCode');

    if (customerCountry && legalCountry !== customerCountry) {
      this.args.item.resetVat();
    } else if (vatRate) {
      this.args.item.vatRate = String(vatRate);
      if (shouldTrack) {
        this.segment.track('invoice_item-title_dropdown_selected');
      }
      this.updateVatExemptionReason({ key: vatExemptionCode });
    }

    this.args.item.links = links;
    this.args.item.type = type;
    this.args.item.productId = id;

    // @ts-expect-error
    if (unitPrice.currency === this.args.currency) {
      this.updateUnitPrice(unitPrice.value);
    }
    this.updateQuantity(1);

    this.segment.track('item_added-from-catalog');
  }

  @action onSaveProductClick() {
    this.segment.track('catalog_add-new_clicked', { source: 'document-creation' });

    let product = this.store.createRecord('product', mapInvoicingItemToProduct(this.args.item));
    let onSaveSuccess = (product: ReceivableInvoiceItemModel) => {
      this.segment.track('catalog_new-item_added', {
        source: 'document-creation',
      });
      this.toastFlashMessages.toastSuccess(
        this.intl.t('products.creation-form.toasts.success.product-added')
      );

      if (product) {
        this.onItemSelect(product, false);
      }

      // @ts-expect-error
      this.productsCatalog.fetchProductsTask.perform().catch(ignoreCancelation);
    };
    let onConfirmedDuplicate = () => {
      this.segment.track('catalog_new-item_duplicate-added', {
        source: 'document-creation',
      });
    };
    let onDiscardedDuplicate = () => {
      this.segment.track('catalog_new-item_duplicate-discarded', {
        source: 'document-creation',
      });
    };

    this.modals.open('products/creation-form-modal', {
      isFullScreenModal: true,
      focusTrapOptions: {
        clickOutsideDeactivates: false,
        allowOutsideClick: true,
        escapeDeactivates: false,
      },
      onSaveSuccess,
      onConfirmedDuplicate,
      onDiscardedDuplicate,
      product,
    });
  }

  get selectedVatOption() {
    if (this.args.item.vatRate === undefined) return undefined;
    let vatCategory = this.organizationManager.organization.vatRatesCategories.find(
      vatRateCategory =>
        Math.abs(vatRateCategory - parseFloat(this.args.item.vatRate) * 100) < VAT_RATE_PRECISION
    );

    if (vatCategory !== undefined)
      return {
        clearable: false,
        value: vatCategory,
      };

    return { clearable: true, value: -1 };
  }

  get itemNumber() {
    // @ts-expect-error
    return this.args.index + 1;
  }

  get shouldDisplayVatExemptionField() {
    return (
      // @ts-expect-error
      parseFloat(this.args.item.vatRate) === 0 || this.args.item.errors.get('vatExemptionCode')
    );
  }

  get shouldDisplayDiscountField() {
    // @ts-expect-error
    if (this.args.hideDiscount) return false;

    if (this.args.isDisabled) {
      return this.args.item.discount;
    }
    return true;
  }

  get shouldOpenDiscountField() {
    return this.args.item?.discount ?? this.showDiscountField;
  }

  @action handleOpenDiscountField() {
    this.showDiscountField = true;
    this.segment.track('invoice-creation_item_add-discount');
  }

  @action
  deleteDiscount() {
    this.showDiscountField = false;
    this.args.item.discount = null;
  }

  @cached
  get vatExemptionReasons() {
    // @ts-expect-error
    let legalCountry = this.args.organization?.legalCountry;
    return getVatExemptionReasons(this.intl, legalCountry);
  }

  get selectedVatExemptionReason() {
    let vatExemptionCode;

    if (this.args.item.vatExemptionCode) {
      vatExemptionCode = this.args.item.vatExemptionCode;
      // @ts-expect-error
    } else if (this.args.item.vatRate === 0 && this.args.organization?.legalCountry === 'IT') {
      vatExemptionCode = 'N2.2';
    } else {
      return undefined;
    }

    return this.vatExemptionReasons.find(
      // @ts-expect-error
      vatExemptionReason => vatExemptionReason.key === vatExemptionCode
    );
  }

  get decimalSeparator() {
    // @ts-expect-error
    return new Intl.NumberFormat(this.intl.primaryLocale)
      .formatToParts(0.5)
      .find(({ type }) => type === 'decimal').value;
  }

  get vatRateErrorMessage() {
    // @ts-expect-error
    let error = this.args.item.get('errors.vatRate.0');

    switch (error?.message) {
      case 'required':
        return this.intl.t('receivable-invoices.invoice-creation.errors.vat.required-field');
      case 'invalid':
        return this.intl.t('receivable-invoices.invoice-creation.errors.vat-over-100');
      default:
        return null;
    }
  }

  get discountPercentageError() {
    // @ts-expect-error
    return this.args.item.get('errors.discount.0');
  }

  get discountAmountError() {
    // @ts-expect-error
    return this.args.item.get('errors.discountAmount.0');
  }

  get discountPercentageErrorMessage() {
    return this.discountPercentageError
      ? this.intl.t('receivable-invoices.invoice-creation.items.discount.percentage-error-message')
      : null;
  }

  get discountAmountErrorMessage() {
    return this.discountAmountError
      ? this.intl.t('receivable-invoices.invoice-creation.items.discount.amount-error-message')
      : null;
  }

  // @ts-expect-error
  @action updateVatExemptionReason({ key }) {
    this.args.onVatResetDisclaimerDismiss?.(true);
    this.args.item.vatExemptionCode = key;
  }

  // @ts-expect-error
  @action updateUnitPrice(price) {
    let [beforeSeparator, afterSeparator] = price.split('.');

    let newPrice = beforeSeparator.slice(0, MAX_UNIT_PRICE_LENGTH_BEFORE_SEPARATOR);
    if (afterSeparator) {
      newPrice = `${newPrice}.${afterSeparator}`;
    }

    // @ts-expect-error
    if (this.args.forcePositive) {
      newPrice = price >= 0 ? newPrice : newPrice * -1;
    }

    // We use a tracked property to trigger a rerender even when the value doesn't change
    // This ensures the input field stays in sync with the actual value
    this.unitPriceInputValue = newPrice;
    this.args.item.unitPrice = newPrice;

    this.resetPaymentMethodAndConditions();
  }

  // @ts-expect-error
  @action updateQuantity(quantity) {
    if (this.args.isCreditNote && quantity > 0) {
      quantity = -quantity;
    }
    // @ts-expect-error
    let newQuantity = this.args.forcePositive && quantity ? Math.abs(quantity) : quantity;

    // We use a tracked property to trigger a rerender even when the value doesn't change
    // This ensures the input field stays in sync with the actual value
    this.quantityInputValue = newQuantity;
    this.args.item.quantity = newQuantity;

    this.resetPaymentMethodAndConditions();
  }

  resetPaymentMethodAndConditions() {
    if (
      // @ts-expect-error
      this.args.document?.totalAmount > SDD_INDIVIDUAL_COLLECTION_LIMIT &&
      // @ts-expect-error
      this.args.document?.directDebitEnabled
    ) {
      // @ts-expect-error
      this.args.document.directDebitEnabled = false;
      // @ts-expect-error
      if (this.args.document.payment?.method && this.args.document.payment?.conditions) {
        // @ts-expect-error
        this.args.document.payment.method = DEFAULT_PAYMENT_METHOD;
        // @ts-expect-error
        this.args.document.payment.conditions = DEFAULT_PAYMENT_CONDITION;
      }
    }
  }

  // @ts-expect-error
  @action handleVatRateInput(newRate) {
    this.setItemVatRate(newRate.replace(/[,.]/g, '.') / 100);
  }

  trackVatRateChange = dropTask(async () => {
    this.segment.track('invoice_item-VAT-other_filled');
    await rawTimeout(60000);
  });

  // @ts-expect-error
  @action handleVatRateChange(newRate) {
    this.setItemVatRate(newRate.replace(/[,.]/g, '.') / 100);

    // track event
    this.trackVatRateChange?.perform();
  }

  trackVatOption = dropTask(async () => {
    this.segment.track('invoice_item-VAT-chip_clicked');
    await rawTimeout(60000);
  });

  // @ts-expect-error
  @action updateVatOption(option) {
    this.args.onVatResetDisclaimerDismiss?.(true);
    if (option.value >= 0) {
      this.setItemVatRate(option.value / 100);

      // track event
      this.trackVatOption?.perform();
    } else if (option.value === undefined) {
      this.setItemVatRate(undefined);
    } else {
      this.setItemVatRate(null);
    }
  }

  // @ts-expect-error
  setItemVatRate(value) {
    this.args.item.vatRate = value === null || value === undefined ? value : String(value);
    if (value !== 0) this.args.item.vatExemptionCode = null;
    // @ts-expect-error
    if (value === 0 && this.args.organization?.legalCountry === 'IT')
      this.args.item.vatExemptionCode = 'N2.2';
    else this.args.item.vatExemptionCode = undefined;
  }

  get totalExVat() {
    // @ts-expect-error
    return this.args.item.quantity * this.args.item.unitPrice;
  }

  get maxTitleLength() {
    return MAX_TITLE_LENGTH;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::Item': typeof ReceivableInvoicesFormItemComponent;
  }
}
