export default {
  "request-expense-report-validation": "cUD",
  "account-selector": "cUV",
  "has-error": "cUL",
  "actions": "cUT",
  "action-btn": "cUz",
  "action-btn-success": "cUa",
  "warnings": "cUH",
  "top-border": "cUO",
  "account-selector-subtitle": "cUm",
  "action-btn-right": "cUp",
  "funds-disclaimer": "cUx",
  "account-options": "cUw",
  "account-balance": "cUN"
};
