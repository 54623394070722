/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { STATUS } from 'qonto/constants/transfers';

interface RequestSidebarCardDetailsSignature {
  // The arguments accepted by the component
  Args: {
    isPending?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestSidebarCardDetailsComponent extends Component<RequestSidebarCardDetailsSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare localeManager: Services['localeManager'];
  @service declare abilities: Services['abilities'];

  CARD_LEVELS = CARD_LEVELS;

  get showRequesterDisclaimer() {
    // @ts-expect-error
    let { request } = this.args;
    let userId = request.get('initiator.userId');
    return this.abilities.can('cancel card request', userId) && request.status === STATUS.PENDING;
  }

  get expirationDate() {
    // @ts-expect-error
    let { expiry } = this.args;

    if (!expiry) {
      return null;
    }

    let preExpiresAt = new Date(expiry);

    return dateToken({
      // @ts-expect-error
      date: preExpiresAt,
      token: DATE_FORMAT_TOKENS.DATE_TIME_S,
      // @ts-expect-error
      locale: this.localeManager.locale,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::Card::Details': typeof RequestSidebarCardDetailsComponent;
  }
}
