export default {
  "header-cell": "coh",
  "empty": "cof",
  "header-content": "coK caption-bold",
  "active": "coG",
  "align-right": "cor",
  "col-8": "cob",
  "col-7": "coy",
  "col-5": "coU",
  "col-4": "coj"
};
