export default {
  "row": "cus",
  "active": "cuF",
  "dot": "cug",
  "red": "cuD",
  "green": "cuV",
  "yellow": "cuL",
  "status": "cuT",
  "align-right": "cuz",
  "animated": "cua",
  "fadein-item": "cuH",
  "cell": "cuO body-2",
  "cell-content": "cum",
  "amount": "cup body-1",
  "subtitle": "cux caption",
  "no-padding": "cuw"
};
