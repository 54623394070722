export default {
  "row": "cAq",
  "active": "cAZ",
  "animated": "cAR",
  "fadein-item": "cAQ",
  "cell": "cAe",
  "cell-content": "cAB",
  "row-sidebar": "cAE",
  "empty": "cAd",
  "cell-amount": "cAI",
  "subtitle": "cAt caption",
  "hidden": "cAM",
  "cell-approve": "cAP",
  "quick-actions": "cAl",
  "account-select": "cAX",
  "note": "cAC",
  "cell-content-amount": "cAk body-1",
  "cell-status": "cAJ"
};
