export default {
  "container": "coz",
  "content": "coa",
  "input-row": "coH",
  "email-row": "coO",
  "button-row": "com",
  "btn-edit": "cop",
  "input-column": "cox",
  "form-btn-row": "cow",
  "border-bottom": "coN",
  "avatar-input": "cov"
};
