export default {
  "card-sidebar-header": "cyq",
  "content": "cyZ",
  "header-type": "cyR",
  "close": "cyQ",
  "close-icon": "cye",
  "header-infos": "cyB",
  "title": "cyE body-1",
  "danger": "cyd",
  "success": "cyI",
  "warning": "cyt",
  "date": "cyM body-2",
  "card-image-container": "cyP",
  "card-image": "cyl",
  "info-container": "cyX",
  "spending-reason": "cyC",
  "spending-reason-label": "cyk body-2 mr-8",
  "spending-reason-note": "cyJ body-2"
};
