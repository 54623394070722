export default {
  "row": "cnS",
  "cell": "cnc",
  "greyed-out": "cnq",
  "struck-through": "cnZ",
  "flex-cell": "cnR",
  "details-container": "cnQ",
  "overdue": "cne",
  "align-right": "cnB",
  "customer-name-container": "cnE",
  "icon": "cnd",
  "warning": "cnI",
  "with-separator": "cnt",
  "align-center": "cnM"
};
