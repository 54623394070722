export default {
  "modal-wrapper": "col",
  "spinner-container": "coX",
  "spinner": "coC",
  "container": "cok",
  "tabs-item": "coJ",
  "form-container": "cos",
  "fieldset": "coF",
  "preview-container": "cog",
  "switch-container": "coD",
  "pdf-preview-container": "coV",
  "form-content": "coL",
  "button-container-footer": "coT"
};
