/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

// @ts-expect-error
import { REQUEST_STATUS_COLORS } from 'qonto/constants/requests';
import { MileageTableCell } from 'qonto/react/components/mileage/table/cell';

interface ReimbursementsTableItemCompletedSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
    isApproveCompactView?: boolean;
    isSidepanelOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class ReimbursementsTableItemCompletedComponent extends Component<ReimbursementsTableItemCompletedSignature> {
  tag = Tag;

  mileageTableCell = MileageTableCell;

  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];

  get requestAmount() {
    // @ts-expect-error
    let { request } = this.args;
    switch (request.requestType) {
      case 'mileage':
        return { value: request.amount.value, currency: request.amount.currency };
      case 'expense_report': {
        return request.amount;
      }
    }
  }

  get displayedStatus() {
    // @ts-expect-error
    switch (this.args.request.status) {
      case 'approved':
        return this.intl.t('request.status.approved');
      case 'declined':
        return this.intl.t('request.status.rejected');
      case 'canceled':
        return this.intl.t('request.status.canceled');
      case 'pending':
        return this.intl.t('request.status.pending');
      default:
        return null;
    }
  }

  get statusColor() {
    // @ts-expect-error
    return REQUEST_STATUS_COLORS[this.args.request.status];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Completed::Table::Item': typeof ReimbursementsTableItemCompletedComponent;
  }
}
