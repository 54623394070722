export default {
  "header": "cUb",
  "header-type": "cUy",
  "close": "cUU",
  "close-icon": "cUj",
  "header-infos": "cUW",
  "title": "cUu body-1",
  "declined": "cjS",
  "canceled": "cjc",
  "approved": "cjq",
  "pending": "cjZ",
  "date": "cjR body-2",
  "mileage-icon": "cjQ",
  "distance-subtitle": "cje body-2",
  "link-icon": "cjB",
  "container": "cjE",
  "picto": "cjd",
  "general": "cjI",
  "amount": "cjt",
  "calculation-container": "cjM",
  "counterparty": "cjP body-2",
  "infobox": "cjl"
};
