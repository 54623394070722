export default {
  "header-cell": "cut",
  "header-content": "cuM caption-bold",
  "active": "cuP",
  "empty": "cul",
  "request-type": "cuX",
  "status": "cuC",
  "note": "cuk",
  "amount": "cuJ"
};
