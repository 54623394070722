/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer, LottiePlayer, Spinner } from '@repo/design-system-kit';

import { REWARDS_STATUS } from 'qonto/constants/referral';
import { ListItem } from 'qonto/react/components/referral';

interface ReferralSidebarSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ReferralSidebarComponent extends Component<ReferralSidebarSignature> {
  disclaimerBlock = Disclaimer.Block;

  lottiePlayer = LottiePlayer;
  listItem = ListItem;
  spinner = Spinner;

  @service declare intl: Services['intl'];

  get tabs() {
    return [
      {
        status: REWARDS_STATUS.EARNED,
        label: this.intl.t('referral.sidebar.content.earned'),
        title: this.intl.t('referral.sidebar.content.total_earned'),
        emptyState: {
          message: this.intl.t('referral.tiered-program.sidebar.earn.empty-state'),
        },
      },
      {
        status: REWARDS_STATUS.PENDING,
        label: this.intl.t('referral.sidebar.content.pending'),
        title: this.intl.t('referral.sidebar.content.total_pending'),
        disclaimer: this.intl.t('referral.tiered-program.sidebar.pending.disclaimer'),
        emptyState: {
          message: this.intl.t('referral.tiered-program.sidebar.pending.empty-state'),
        },
      },
      {
        status: REWARDS_STATUS.EXPIRED,
        label: this.intl.t('referral.tiered-program.sidebar.expiration.tab'),
        disclaimer: this.intl.t('referral.tiered-program.sidebar.expiration.disclaimer'),
        emptyState: {
          message: this.intl.t('referral.tiered-program.sidebar.expiration.empty-state'),
        },
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Referral::Sidebar': typeof ReferralSidebarComponent;
  }
}
