export default {
  "container": "cxr",
  "title-and-trash": "cxb",
  "trash": "cxy",
  "quantity-amount": "cxU",
  "flex-row": "cxj",
  "quantity": "cxW",
  "quantityInput": "cxu",
  "border-left": "cwS",
  "dropdown": "cwc",
  "item-row": "cwq",
  "unit-price": "cwZ",
  "price-input": "cwR",
  "hidden": "cwQ",
  "d-none": "cwe",
  "dropdown-option": "cwB",
  "list-row": "cwE",
  "row-title": "cwd",
  "row-price": "cwI body-2",
  "row-missing-details-container": "cwt",
  "row-missing-details-label": "cwM body-2",
  "warning-icon": "cwP",
  "row-internal-note": "cwl",
  "with-internal-note": "cwX",
  "dropdown-content": "cwC",
  "select-item": "cwk"
};
