export default {
  "invoice-sidebar": "cNn",
  "body": "cNi",
  "box": "cNA",
  "box-header": "cNY",
  "footer": "cNh",
  "footer-button": "cNf",
  "sidebar-box": "cNK",
  "border-top": "cNG",
  "row": "cNr",
  "status-container": "cNb",
  "due-date-container": "cNy",
  "mark-as-btn": "cNU",
  "primary-actions": "cNj cNK",
  "primary-action": "cNW body-1",
  "button-icon": "cNu"
};
