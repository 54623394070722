export default {
  "payment-details": "cwJ",
  "label-payment-conditions": "cws",
  "dropdown-content": "cwF",
  "dropdown-option": "cwg",
  "activate-sdd-container": "cwD",
  "activate-sdd-header": "cwV",
  "selector-toggle-sdd": "cwL",
  "selector-toggle-sdd-subtitle": "cwT",
  "selector-toggle-content": "cwz",
  "selector-toggle": "cwa",
  "new-badge": "cwH",
  "new-badge-activation": "cwO",
  "mt-16": "cwm",
  "sdd-mandate-select-loading": "cwp"
};
