export default {
  "header-cell": "cHL",
  "checkbox-cell": "cHT",
  "title-col": "cHz",
  "internal-note-col": "cHa",
  "unit-col": "cHH",
  "price-col": "cHO",
  "header-content": "cHm caption-bold",
  "active": "cHp",
  "empty": "cHx",
  "align-checkbox": "cHw"
};
