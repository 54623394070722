export default {
  "wrapper": "qcl",
  "header": "qcX mb-48",
  "form-title": "qcC title-2 mb-16",
  "form-subtitle": "qck body-2",
  "form": "qcJ",
  "section": "qcs mb-48",
  "section-title": "qcF title-3 mb-16",
  "form-footer": "qcg",
  "footer-btn": "qcD",
  "transfer-form": "qcV"
};
