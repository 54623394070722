export default {
  "section-container": "cff",
  "disclaimer-section": "cfK",
  "trip-title": "cfG caption-bold mb-16",
  "google-link-container": "cfr",
  "link-icon": "cfb",
  "trip-details": "cfy body-2",
  "trip-details-icon": "cfU",
  "trip-details-title": "cfj",
  "subtitle": "cfW caption-bold mb-16",
  "disclaimer": "cfu",
  "visualizer": "cKS"
};
