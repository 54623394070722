export default {
  "header": "cyf",
  "header-type": "cyK",
  "close": "cyG",
  "close-icon": "cyr",
  "header-infos": "cyb",
  "title": "cyy body-1",
  "declined": "cyU",
  "canceled": "cyj",
  "approved": "cyW",
  "pending": "cyu",
  "date": "cUS body-2",
  "container": "cUc",
  "picto": "cUq",
  "picto-status": "cUZ",
  "general": "cUR",
  "amount": "cUQ",
  "counterparty": "cUe body-2",
  "initiator": "cUB",
  "initiator-avatar": "cUE",
  "avatar-image": "cUd",
  "initiator-id": "cUI",
  "name": "cUt body-2",
  "role": "cUM caption-bold",
  "infobox": "cUP",
  "account-infobox": "cUl cUP",
  "beneficiary-warning": "cUX caption-bold",
  "dates-wrapper": "cUC",
  "dates": "cUk",
  "recurrence": "cUJ caption-bold",
  "arrow": "cUs",
  "notify-checkbox": "cUF"
};
