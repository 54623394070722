export default {
  "client-form": "cmO",
  "align-start": "cmm",
  "kind": "cmp",
  "name-fields": "cmx",
  "field": "cmw",
  "required-data-disclaimer": "cmN",
  "row": "cmv",
  "child": "cmo",
  "delete-action": "cmn",
  "legal-info": "cmi",
  "column-layout": "cmA"
};
