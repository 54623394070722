export default {
  "modal": "cvU",
  "action-container": "cvj",
  "similar-label": "cvW",
  "info-icon": "cvu",
  "container": "coS",
  "subtitle": "coc caption-bold",
  "error": "coq",
  "item-container": "coZ"
};
