export default {
  "row": "qqF",
  "row-active": "qqg",
  "avatar": "qqD",
  "cell": "qqV",
  "cell-content": "qqL",
  "cell-counterparty": "qqT",
  "counterparty-name": "qqz",
  "cell-amount": "qqa",
  "status-avatar": "qqH",
  "counterparty": "qqO",
  "creditor-name": "qqm",
  "reference": "qqp",
  "counterparty-details": "qqx",
  "cell-quick-actions": "qqw",
  "quick-actions": "qqN",
  "account-selector": "qqv"
};
