export default {
  "summary-wrapper": "civ",
  "summary-title": "cio",
  "summary-list": "cin",
  "summary-list-item-wrapper": "cii",
  "summary-list-item-record": "ciA",
  "summary-list-item-avatar": "ciY",
  "summary-list-item-avatar-image": "cih",
  "summary-list-item-name-count": "cif",
  "item-count": "ciK"
};
