export default {
  "item": "coR",
  "details": "coQ",
  "flex": "coe",
  "header": "coB coe",
  "flex-no-gap": "coE coe",
  "file-name": "cod",
  "label": "coI body-2",
  "self-icon": "cot",
  "with-separator": "coM",
  "dimmed": "coP"
};
