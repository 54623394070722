export default {
  "document": "cvn",
  "document-details": "cvi",
  "document-details-amount": "cvA body-2",
  "negative": "cvY",
  "positive": "cvh",
  "rejected": "cvf",
  "document-icon": "cvK",
  "active": "cvG",
  "document-details-link": "cvr body-link",
  "canceled": "cvb",
  "status-badge": "cvy"
};
