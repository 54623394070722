export default {
  "invoice-sidebar": "cNc",
  "body": "cNq",
  "box": "cNZ",
  "box-header": "cNR",
  "footer": "cNQ",
  "footer-button": "cNe",
  "footer-button-tooltip": "cNB",
  "reduced-fontsize": "cNE",
  "sidebar-box": "cNd",
  "border-top": "cNI",
  "row": "cNt",
  "status-container": "cNM",
  "due-date-container": "cNP",
  "greyed-out": "cNl",
  "struck-through": "cNX",
  "overdue": "cNC",
  "primary-actions": "cNk cNd",
  "primary-action": "cNJ",
  "danger-action": "cNs",
  "button-icon": "cNF",
  "button-label": "cNg body-2",
  "related-credit-note-title": "cND caption-bold",
  "paid-at-placeholder": "cNV",
  "collapsible-timeline": "cNL",
  "timeline-status-label": "cNT",
  "related-documents": "cNz"
};
