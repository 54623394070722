/* import __COLOCATED_TEMPLATE__ from './tasks-table.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Header } from 'qonto/react/components/riba/tasks-table/header/header';
import type { Services } from 'qonto/services/top-banners/config-types';

interface RibaTasksTableSignature {
  Args: {
    payments: Array<any>;
    navigation: any;
    changePage: (page: number) => void;
    changePerPage: (perPage: number) => void;
    isLoading: boolean;
    onSelectItem: (payment: any, isChecked: any) => void;
  };
}

export default class RibaTasksTable extends Component<RibaTasksTableSignature> {
  Header = Header;

  @service declare organizationManager: Services['organizationManager'];
}
