export default {
  "card": "cps",
  "error": "cpF",
  "customer-info": "cpg",
  "edit-customer-tooltip": "cpD",
  "title": "cpV",
  "flex-row": "cpL",
  "dot-separator": "cpT",
  "flex-span": "cpz",
  "faq-link": "cpa",
  "tooltip-wrapper": "cpH",
  "badge-union": "cpO"
};
