export default {
  "header": "cfc",
  "header-type": "cfq",
  "close": "cfZ",
  "close-icon": "cfR",
  "header-infos": "cfQ",
  "title": "cfe body-1",
  "declined": "cfB",
  "canceled": "cfE",
  "approved": "cfd",
  "pending": "cfI",
  "date": "cft body-2",
  "container": "cfM",
  "picto": "cfP",
  "picto-status": "cfl",
  "general": "cfX",
  "amount": "cfC",
  "counterparty": "cfk body-2",
  "initiator": "cfJ",
  "initiator-avatar": "cfs",
  "avatar-image": "cfF",
  "initiator-id": "cfg",
  "name": "cfD body-2",
  "role": "cfV caption-bold",
  "infobox": "cfL",
  "account-infobox": "cfT cfL",
  "beneficiary-warning": "cfz caption-bold",
  "dates-wrapper": "cfa",
  "dates": "cfH",
  "recurrence": "cfO caption-bold",
  "arrow": "cfm",
  "notify-checkbox": "cfp"
};
