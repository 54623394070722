/* import __COLOCATED_TEMPLATE__ from './payment-details.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

import { Badge, Disclaimer, TextAreaField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { SDD_INDIVIDUAL_COLLECTION_LIMIT } from 'qonto/constants/direct-debit-collections';
import { PROMO_BOX_LOCAL_STORAGE_KEY } from 'qonto/constants/invoice-subscriptions';
// @ts-expect-error
import ITALIAN_PAYMENT_CONDITIONS from 'qonto/constants/it-payment-conditions';
// @ts-expect-error
import ITALIAN_PAYMENT_METHODS from 'qonto/constants/it-payment-methods';
import {
  DEFAULT_PAYMENT_CONDITION,
  DEFAULT_PAYMENT_METHOD,
  SDD_PAYMENT_CONDITION,
  SDD_PAYMENT_METHOD,
  TERM_AND_CONDITIONS_MAX_LENGTH,
  TERM_AND_CONDITIONS_MAX_LENGTH_FOR_IT,
} from 'qonto/constants/receivable-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface PaymentDetailsSignature {
  // The arguments accepted by the component
  Args: {
    isQuote?: boolean;
    isEInvoicing?: boolean;
    isEdit?: boolean;
    isMandatesLoadingError?: boolean;
    isSavingSubscription?: boolean;
    isMandatesLoading?: boolean;
    isDEInvoicing?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PaymentDetailsComponent extends Component<PaymentDetailsSignature> {
  badge = Badge;
  disclaimerInline = Disclaimer.Inline;
  textAreaField = TextAreaField;

  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];

  // @ts-expect-error
  @reads('organizationManager.organization.activeSortedAccounts') activeAccounts;
  // @ts-expect-error
  @reads('args.document.customer.content') selectedCustomer;
  // @ts-expect-error
  @reads('args.document.bankAccount.content') selectedAccount;
  // @ts-expect-error
  @reads('args.isRecurringInvoice') isRecurringInvoice;
  // @ts-expect-error
  @reads('args.sddEligibility') sddEligibility;

  constructor(owner: unknown, args: PaymentDetailsSignature['Args']) {
    super(owner, args);
    if (!this.args.isQuote) {
      // @ts-expect-error
      if (this.args.document.belongsTo('bankAccount').id() === null) {
        next(() => this._updateBankAccount(this.activeAccounts?.[0]));
      }
    }
  }

  get showTitle() {
    return this.showBankAccount || this.showSDDActivationCTA || this.showEInvoicing;
  }

  get showBankAccount() {
    return this.abilities.cannot('write paymentLink');
  }

  get showEInvoicing() {
    return this.args.isEInvoicing;
  }

  get sddActivationQuery() {
    if (this.args.isEdit) {
      return {
        origin: 'invoice-subscriptions.edit',
      };
    }
    return {
      origin: 'invoice-subscriptions.new',
    };
  }

  get showSDDActivationCTA() {
    let { isEligible, isActivated } = this.sddEligibility || {};

    return this.isRecurringInvoice && isEligible && !isActivated;
  }

  get guardingPeriodPercentage() {
    return this.intl.formatNumber(this.sddEligibility.guardingDepositPercentage, {
      style: 'percent',
    });
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get accountsToDebit() {
    // @ts-expect-error
    return this.activeAccounts.filter(account => account.isDebitAccount);
  }

  get hasBankAccountError() {
    // @ts-expect-error
    return this.args.document.errors.has('bankAccount/data/id');
  }

  @cached
  get paymentMethods() {
    return Object.keys(ITALIAN_PAYMENT_METHODS).map(key => {
      return { key, label: key + ' - ' + ITALIAN_PAYMENT_METHODS[key] };
    });
  }

  get selectedPaymentMethod() {
    // @ts-expect-error
    let paymentDocumentMethod = this.args.document.payment?.method;

    return this.paymentMethods.find(paymentMethod => paymentMethod.key === paymentDocumentMethod);
  }

  get showSelectorToggleSddDisclaimer() {
    return this.sddEligibility.isActivated && !this.tooltipMessage;
  }

  get selectedPaymentMethodSdd() {
    return this.paymentMethods.find(paymentMethod => paymentMethod.key === SDD_PAYMENT_METHOD);
  }

  get selectedPaymentConditionsSdd() {
    return this.paymentConditions.find(
      paymentCondition => paymentCondition.key === SDD_PAYMENT_CONDITION
    );
  }

  @cached
  get paymentConditions() {
    return Object.keys(ITALIAN_PAYMENT_CONDITIONS).map(key => {
      return { key, label: key + ' - ' + ITALIAN_PAYMENT_CONDITIONS[key] };
    });
  }

  get selectedPaymentCondition() {
    // @ts-expect-error
    let paymentDocumentCondition = this.args.document.payment?.conditions;

    return this.paymentConditions.find(
      paymentCondition => paymentCondition.key === paymentDocumentCondition
    );
  }

  get termsTitle() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.additional-notes.title');
    }
    return this.intl.t('receivable-invoices.invoice-creation.payment-details.terms.label');
  }

  get termsPlaceholder() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.additional-details.terms.placeholder');
    }
    return this.intl.t('receivable-invoices.invoice-creation.payment-details.terms.placeholder');
  }

  get getPdfLocale() {
    return this.selectedCustomer?.locale || this.intl.primaryLocale || 'en';
  }

  get getCapitalShareLabel() {
    return {
      en: 'with a capital of',
      fr: 'avec un capital de',
      de: 'mit einem Kapital von',
      it: 'con un capitale di',
      es: 'con un capital de',
    };
  }

  get formatCapitalShare() {
    // @ts-expect-error
    return this.intl.formatNumber(this.args.settings?.legalCapitalShare, {
      locale: this.getPdfLocale,
      currency: 'EUR',
      style: 'currency',
    });
  }

  // This is the length used for legal info in the FR footer, it looks like:
  // 'Merchant Company, SAS with a capital of €1,000,000,000.00 · RCS A Paris 123 456 789 · '
  // As the invoice pdf footer has a strict limit, we need to check the amount of characters used, in order to calculate the remaining available amount of characters
  get getLengthUsedForFRFooter() {
    let { organization } = this.organizationManager;

    // Customer's locale
    let pdfLocale = this.getPdfLocale;
    // Capital share amount formatted in currency style
    // @ts-expect-error
    let capitalShare = this.args.settings?.legalCapitalShare ? this.formatCapitalShare : '';
    // Label text for the capital share
    // @ts-expect-error
    let capitalShareLabel = this.args.settings?.legalCapitalShare
      ? // @ts-expect-error
        this.getCapitalShareLabel[pdfLocale]
      : '';
    // FR rsc number
    // @ts-expect-error
    let rscNumber = this.args.settings?.commercialRegisterNumber
      ? // @ts-expect-error
        this.args.settings.commercialRegisterNumber
      : '';
    // We add 3 characters because we separate the legal name from the rscNumber (if any) with " - "
    // @ts-expect-error
    let dashSpace = this.args.settings?.commercialRegisterNumber ? 3 : 0;
    // We add space between the legal name and the legalCapitalShare
    // @ts-expect-error
    let space = this.args.settings?.legalCapitalShare ? 2 : 0;

    return (
      `${organization.legalName}, ${organization.shortLegalForm}${capitalShareLabel}${capitalShare}${rscNumber}`
        .length +
      dashSpace +
      space
    );
  }

  get termsAndConditionsMaxLength() {
    let theoreticalMaxLength = TERM_AND_CONDITIONS_MAX_LENGTH;
    if (this.legalCountry === 'IT') {
      theoreticalMaxLength = TERM_AND_CONDITIONS_MAX_LENGTH_FOR_IT;
    }

    let maxLength = theoreticalMaxLength - this.prefixTermsAndConditionsLength;
    return String(maxLength);
  }

  // As the invoice pdf footer has a strict limit, we need to check the amount of characters used, in order to calculate the remaining available amount of characters
  get prefixTermsAndConditionsLength() {
    let length = 0;

    let { organization } = this.organizationManager;

    // We add 3 characters because we separate the prefix from actual terms and conditions with " - "
    let dashSpace = 3;

    if (this.legalCountry === 'FR') {
      length = this.getLengthUsedForFRFooter + dashSpace;
    } else {
      length = `${organization.legalName}, ${organization.shortLegalForm}`.length + dashSpace;
    }

    return length;
  }

  get showEnableSDDToggle() {
    let {
      isActivated,
      hasPendingCreditorIdentifierVerification,
      hasPendingCreditorIdentifierCreation,
    } = this.sddEligibility || {};

    return (
      this.isRecurringInvoice &&
      (isActivated ||
        hasPendingCreditorIdentifierVerification ||
        hasPendingCreditorIdentifierCreation)
    );
  }

  get sddActive() {
    // @ts-expect-error
    return this.args.document?.directDebitEnabled;
  }

  get tooltipMessage() {
    // @ts-expect-error
    let { currency, totalAmount } = this.args.document;
    if (
      this.sddEligibility?.hasPendingCreditorIdentifierVerification ||
      this.sddEligibility?.hasPendingCreditorIdentifierCreation
    ) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.sdd-toggle.pending-tooltip'
      );
    } else if (currency !== 'EUR') {
      return this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.sdd-toggle.non-sepa-tooltip'
      );
    } else if (!this.selectedCustomer) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.sdd-toggle.tooltip.select-client'
      );
    } else if (this.selectedCustomer && !this.selectedCustomer?.email) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.sdd-toggle.missing-email-tooltip'
      );
    } else if (totalAmount > SDD_INDIVIDUAL_COLLECTION_LIMIT) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.sdd-toggle.amount-tooltip'
      );
    }
  }

  @action onClickToggle() {
    // @ts-expect-error
    this.args.document.directDebitEnabled = !this.args.document?.directDebitEnabled;

    // @ts-expect-error
    this.args.document.directDebitCollectionMandate = this.args.document.directDebitEnabled
      ? // @ts-expect-error
        this.args.mandates[0]
      : null;

    if (this.sddActive) {
      this.updatePaymentMethod({ key: SDD_PAYMENT_METHOD });
      this.updatePaymentCondition({ key: SDD_PAYMENT_CONDITION });
    } else {
      this.updatePaymentMethod({ key: DEFAULT_PAYMENT_METHOD });
      this.updatePaymentCondition({ key: DEFAULT_PAYMENT_CONDITION });
    }
  }

  @action onSddActivationFlowStart() {
    // @ts-expect-error
    safeLocalStorage.setItem(PROMO_BOX_LOCAL_STORAGE_KEY, true);
  }

  // @ts-expect-error
  @action onBankAccountSelect(account) {
    this._updateBankAccount(account);
  }

  // @ts-expect-error
  @action updatePaymentMethod({ key }) {
    // @ts-expect-error
    this.args.document.payment.method = key;
  }

  // @ts-expect-error
  @action updatePaymentCondition({ key }) {
    // @ts-expect-error
    this.args.document.payment.conditions = key;
  }

  // @ts-expect-error
  _updateBankAccount(account) {
    // @ts-expect-error
    this.args.document.bankAccount = account;
    // @ts-expect-error
    this.args.document.iban = account?.iban;
    // @ts-expect-error
    this.args.document.bic = account?.bic;
    // @ts-expect-error
    this.args.document.beneficiaryName = this.organizationManager.organization.name;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::PaymentDetails': typeof PaymentDetailsComponent;
  }
}
