export default {
  "container": "cvV",
  "main": "cvL",
  "preview": "cvT",
  "form": "cvz",
  "scrollable": "cva",
  "header": "cvH title-1",
  "form-sections": "cvO",
  "form-section": "cvm",
  "footer": "cvp",
  "preview-header": "cvx",
  "centered": "cvw",
  "preview-content-wrapper": "cvN",
  "preview-content": "cvv",
  "payment-link-preview": "cvo"
};
