export default {
  "qonto-pilot-modal": "cOw",
  "content": "cON",
  "header": "cOv",
  "transition-state-container": "cOo",
  "lottie": "cOn",
  "prompt-input": "cOi",
  "buttons": "cOA",
  "footer": "cOY caption",
  "divider": "cOh",
  "illustration": "cOf",
  "icon": "cOK",
  "quick-actions-container": "cOG",
  "action": "cOr body-2",
  "action-title": "cOb",
  "disabled": "cOy",
  "action-tag": "cOU caption-bold",
  "hotkey": "cOj body-1",
  "footer-action": "cOW",
  "grow": "cOu",
  "footer-feedback-link": "cmS",
  "buttons-container": "cmc",
  "transfer-details-container": "cmq",
  "error-container": "cmZ",
  "option-iban": "cmR",
  "actions": "cmQ",
  "title": "cme",
  "group-title": "cmB caption-bold",
  "beta-badge": "cmE",
  "hotkeys": "cmd",
  "supplier-invoices-dropzone": "cmI",
  "success-icon": "cmt",
  "error-icon": "cmM",
  "file-items": "cmP",
  "file-item": "cml",
  "uploader-file": "cmX",
  "transactions-table": "cmC",
  "transaction": "cmk",
  "prompt-example": "cmJ"
};
