export default {
  "row": "cur",
  "active": "cub",
  "dot": "cuy",
  "red": "cuU",
  "green": "cuj",
  "yellow": "cuW",
  "status": "cuu",
  "align-right": "qSS",
  "animated": "qSc",
  "fadein-item": "qSq",
  "cell": "qSZ body-2",
  "cell-content": "qSR",
  "amount": "qSQ body-2",
  "subtitle": "qSe caption",
  "padding-left": "qSB",
  "padding-right": "qSE",
  "note": "qSd"
};
