/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

interface ReimbursementsPendingTableSignature {
  // The arguments accepted by the component
  Args: {
    isSingleAccountApprover?: boolean;
    isSidepanelOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReimbursementsPendingTable extends Component<ReimbursementsPendingTableSignature> {
  get isApproveCompactView() {
    return this.args.isSingleAccountApprover && !this.args.isSidepanelOpen;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Completed::Table': typeof ReimbursementsPendingTable;
  }
}
