export default {
  "section-container": "cAN",
  "disclaimer-section": "cAv",
  "trip-title": "cAo caption-bold mb-16",
  "google-link-container": "cAn",
  "link-icon": "cAi",
  "trip-details": "cAA body-2",
  "trip-details-icon": "cAY",
  "trip-details-title": "cAh",
  "subtitle": "cAf caption-bold mb-16",
  "disclaimer": "cAK",
  "visualizer": "cAG"
};
