export default {
  "row": "qqo",
  "cell": "qqn",
  "checkbox": "qqi",
  "icon": "qqA",
  "creditor-item-container": "qqY",
  "actions-item-container": "qqh",
  "due-date": "qqf",
  "amount": "qqK",
  "align-right": "qqG"
};
